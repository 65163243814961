import { get } from 'lodash';

export class ApiError {
  private _error: any;
  constructor(error: any = {}) {
    this._error = error;
  }
  public getResponse() {
    return get(this._error, 'response', {});
  }
  public getError() {
    return get(this._error, 'response.data', {});
  }
  public getErrorDetails() {
    return get(this._error, 'response.data.details', {});
  }
  public getStatus() {
    return get(this._error, 'response.status', {});
  }
  public getRequest() {
    return get(this._error, 'request', {});
  }
  public getMessage() {
    return get(this._error, 'message', 'No data response');
  }
}