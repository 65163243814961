const template = {
  id: "storeTable",
  collection: "store",
  view: "table",
  template: {
    type: "space",
    items: [
      {
        type: "space",
        cssClass: "space-padding",
        direction: "horizontal",
        items: [
          {
            id: "newStore",
            type: "button",
            subType: "primary",
            shape: "round",
            icon: {
              type: "icon",
              subType: "plus",
              theme: "outlined",
            },
            label: "GENERAL.CREATE",
            onClick: ({ openCollection }: any) => {
              if (openCollection) {
                openCollection("store", "edit", "new");
              }
            },
          },
        ],
      },
      {
        id: "store",
        type: "table",
        rowKey: "_id",
        canDelete: false,
        searchable: true,
        onSearch: (value: string, pipe: any) => {
          const { setFilters, setKeyword, refreshTable, handlePageChange } = pipe;
          setKeyword(value);
          setFilters([
            "name",
            "customerLoc",
            "shippingLoc",
            "asnBuyerLocEAN",
            "ppoBuyerLocEAN",
          ]);
          handlePageChange({ page: 1 });
          refreshTable();
        },
        columns: [
          {
            title: "STORE.NAME",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "STORE.CUSTOMER_LOC",
            dataIndex: "customerLoc",
            key: "customerLoc",
          },
          {
            title: "STORE.SHIPPING_LOC",
            dataIndex: "shippingLoc",
            key: "shippingLoc",
          },
          {
            title: "STORE.BUYER_CODE_ASN",
            dataIndex: "asnBuyerLocEAN",
            key: "asnBuyerLocEAN",
          },
          {
            title: "STORE.BUYER_CODE_PPO",
            dataIndex: "ppoBuyerLocEAN",
            key: "ppoBuyerLocEAN",
          },
          {
            title: "STORE.PPOWEEK",
            dataIndex: "ppoweek",
            key: "ppoweek",
          },
        ],
      },
    ],
  },
};

export { template };
