import { Collection } from "../services/collection";
import { post } from "../services/request";
import { template as table } from '../templates/tp-poresponse';
import { template as edit } from '../templates/tp-poresponse-edit';

export class Poresponse extends Collection {
  constructor(auth?: any) {
    super('poresponse', {
      table,
      edit
    }, auth);
  }
  public upload = async (reportNum: string) => post(`upload/${reportNum}`, {});
}