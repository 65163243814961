import { filter, sumBy } from "lodash";
import moment from "moment";
import { Invrpt } from "../collections/invrpt"
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";
import { Promise } from 'bluebird';
import { DynamicIcon } from "../components/icon";

const template = {
  id: 'invrptTable',
  collection: 'invrpt',
  view: 'table',
  template: {
    type: 'space',
    items: [{
      type: 'space',
      cssClass: 'space-padding',
      items: [{
        id: 'fetch',
        type: 'button',
        subType: 'primary',
        shape: 'round',
        icon: {
          type: 'icon',
          subType: 'cloud-download',
          theme: 'outlined'
        },
        label: 'GENERAL.FETCH',
        showLoading: true,
        onClick: async (pipe : any) => {
          const { setLoadings, refreshTable } = pipe;
          try {
            const invrptSvc = new Invrpt();
            setLoadings((prevLoadings: any) => ({ ...prevLoadings, fetch: true }))
            const { numOfFiles, numOfMessages } = await invrptSvc.fetch();
            showToast({
              type: 'success',
              title: 'GENERAL.SUCCESS',
              description: `Number of files: ${numOfFiles}\nNumber of Messages: ${numOfMessages}`,
            }, pipe.t);
          } catch (error) {
            showToast({
              type: 'error',
              title: 'ERROR.TITLE',
              description: (error as ApiError).getMessage()
            }, pipe.t);
          } finally {
            setLoadings((prevLoadings: any) => ({ ...prevLoadings, fetch: false }));
            refreshTable();
          }
        }
      }],
    }, {
      id: 'invrpt',
      type: 'table',
      rowKey: '_id',
      canDelete: false,
      canEdit: false,
      canView: false,
      extraActions: [{
        icon: {
          type: 'icon',
          subType: 'send',
          theme: 'outlined'
        },
        id: 'send',
        onClick: async (event: any, { openCollection, setLoadings }: any) => {
          try {
            setLoadings((prevLoadings: any) => ({ ...prevLoadings, send: true }));
            if (openCollection) {
              const { originalRecord } = event;
              const { storeEAN, line } = originalRecord || {};
              openCollection('proposedpo', 'edit', 'new', {
                storeEAN,
                messageDate: moment().format('YYYY-MM-DD'),
                line: filter(await Promise.mapSeries(line, async ({ haItemNum, itemEAN, quantity, needPPO, setting }: any) => {
                  const totalQty = sumBy(quantity, 'quantity');
                  return !!needPPO && ({
                    haItemNum,
                    itemEAN,
                    quantity: [{
                      type: '21',
                      quantity: setting.maxBX ? setting.maxBX - totalQty : 0
                    }]
                  })}), o => !!o)
              });
            }
          } finally {
            setLoadings((prevLoadings: any) => ({ ...prevLoadings, send: false }))
          }
        }
      }, {
        icon: {
          type: 'icon',
          subType: 'check-square',
          theme: 'outlined'
        },
        id: 'check',
        hidden: ({ data }: any) => data.status === 'PROCESSED',
        onClick: async (event: any, { setLoadings, refreshTable }: any) => {
          try {
            setLoadings((prevLoadings: any) => ({ ...prevLoadings, check: true }));
            const { originalRecord } = event;
            const { _id } = originalRecord || {};
            const invrptSvc = new Invrpt();
            await invrptSvc.update(_id, { status: 'PROCESSED'});
          } finally {
            setLoadings((prevLoadings: any) => ({ ...prevLoadings, check: false }))
            refreshTable();
          }
        }
      }],
      columns: [{
        title: 'INVRPT.NEED_PPO',
        dataIndex: 'needPPO',
        key: 'needPPO',
        render: (data: any) => {
          return !!data && <DynamicIcon theme='outlined' type='exclamation-circle' ></DynamicIcon>
        }
      },
      {
        title: 'INVRPT.REPORT_NUM',
        dataIndex: 'reportNum',
        key: 'reportNum',
      },
      {
        title: 'INVRPT.REPORT_DATE',
        dataIndex: 'reportDate',
        key: 'reportDate',
      },
      {
        title: 'INVRPT.STORE_LOC_NUM',
        dataIndex: 'storeEAN',
        key: 'storeEAN',
      }, {
        title: 'COMMON.STATUS',
        dataIndex: 'status',
        key: 'status'
      }],
      transform: {
        storeEAN: {
          collection: 'store',
          showValue: true,
          labelKey: 'name',
          valueKey: 'ppoBuyerLocEAN'
        }
      },
      dataType: {
        refNum: {
          type: 'string'
        },
        reportNum: {
          type: 'string'
        },
        reportDate: {
          type: 'string'
        },
        storeEAN: {
          type: 'string'
        }
      },
      expandable: {
        rowKey: 'lineNum',
        columns: [{
          title: 'INVRPT.NEED_PPO',
          dataIndex: 'needPPO',
          key: 'needPPO',
          render: (data: any) => {
            return !!data && <DynamicIcon theme='outlined' type='exclamation-circle' ></DynamicIcon>
          }
        }, {
          title: 'INVRPT.GTIN',
          dataIndex: 'itemEAN',
          key: 'itemEAN',
        }, {
          title: 'INVRPT.HAITEM_NUM',
          dataIndex: 'haItemNum',
          key: 'haItemNum',
        }, {
          title: 'CFN',
          dataIndex: 'cfn',
          key: 'cfn',
        }, {
          title: 'Brand',
          dataIndex: 'brand',
          key: 'brand',
        }],
        id: 'line',
        transform: {
          haItemNum: {
            collection: 'inventory',
            labelKey: 'cfn',
            valueKey: 'haItemCode',
            to: 'cfn'
          },
          itemEAN: {
            collection: 'inventory',
            labelKey: 'brand',
            valueKey: 'gtin',
            to: 'brand'
          }
        },
        expandable: {
          rowKey: 'batchNum',
          title: 'INVRPT.QUANTITY',
          columns: [{
            title: 'INVRPT.TYPE',
            dataIndex: 'type',
            key: 'type'
          }, {
            title: 'INVRPT.QUANTITY',
            dataIndex: 'quantity',
            key: 'quantity'
          }, {
            title: 'INVRPT.LOT',
            dataIndex: 'batchNum',
            key: 'batchNum'
          }, {
            title: 'INVRPT.EXPIRY_DATE',
            dataIndex: 'expiryDate',
            key: 'expiryDate'
          },],
          id: 'quantity'
        }
      }
    }]
  }
}

export {
  template
}