const template = {
  id: 'inventoryView',
  collection: 'inventory',
  view: 'view',
  template: {
    type: 'dialog',
    title: 'INVENTORY.VIEW',
    closable: true,
    hideFooter: true,
    items: [{
      type: 'desc',
      id: 'newView',
      bordered: true,
      layout: 'vertical',
      items: [{
        type: 'label',
        id: 'cfn',
        label: 'INVENTORY.CFN',
      }, {
        type: 'label',
        id: 'name',
        label: 'INVENTORY.NAME',
      }, {
        type: 'label',
        label: 'INVENTORY.DESCRIPTION',
        id: 'description',
      }, {
        type: 'label',
        id: 'haItemCode',
        label: 'INVRPT.HAITEM_NUM',
      },  {
        type: 'label',
        id: 'gtin',
        label: 'INVRPT.GTIN',
      }, {
        type: 'label',
        id: 'packSize',
        label: 'INVENTORY.PACKSIZE',
      }, {
        type: 'label',
        id: 'unitPrice',
        label: 'INVENTORY.PRICE',
      },
      {
        type: 'label',
        label: 'INVENTORY.UOM',
        id: 'uom',
      },
      {
        type: 'label',
        label: 'INVENTORY.BRAND',
        id: 'brand',
      }, 
      {
        type: 'label',
        label: 'INVENTORY.MANUFACTURER',
        id: 'manufacturer',
      }, 
      {
        type: 'label',
        label: 'INVENTORY.COUNTRY',
        id: 'country',
      }, 
      {
        type: 'label',
        label: 'INVENTORY.CONTRACTNUM',
        id: 'contractNo',
      }],
    }, {
      type: 'table',
      rowKey: 'lotNo',
      canDelete: false,
      canEdit: false,
      canView: false,
      columns: [{
        title: 'INVENTORY.LOTNUM',
        dataIndex: 'lotNo',
        key: 'lotNo'
      }, {
        title: 'INVENTORY.EXPIRYDATE',
        dataIndex: 'expiryDate',
        key: 'expiryDate',
      }, {
        title: 'INVENTORY.QUANTITY',
        dataIndex: 'quantity',
        key: 'quantity',
      }],
      id: 'lots',
    }]
  }
}

export {
  template
}