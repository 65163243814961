import { isFunction } from "lodash";
import { Inventorystore } from "../collections/inventorystore";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const onFinish = async (values: any, pipe: any) => {
  const { t, handleDialogConfirm, currentCollection, auth } = pipe;
  const updateModel = values;
  console.log("Received values of form: ", updateModel);
  const { mode } = currentCollection;
  const inventoryStoreSvc = new Inventorystore(auth);
  try {
    if (mode === "new") {
      await inventoryStoreSvc.create(updateModel);
    } else {
      await inventoryStoreSvc.update(mode, updateModel);
    }
  } catch (error: any) {
    showToast(
      {
        type: "error",
        title: "ERROR.TITLE",
        description: (error as ApiError).getMessage(),
      },
      t
    );
  } finally {
    if (isFunction(handleDialogConfirm)) {
      handleDialogConfirm(pipe);
    }
  }
};

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const template = {
  id: "inventorystoreEdit",
  collection: "inventorystore",
  view: "edit",
  template: {
    type: "dialog",
    title: "INVENTORYSTORE.NEW",
    closable: false,
    onOK: ({ model }: any) => {
      console.log("onOK", model);
    },
    onCancel: ({ model }: any) => {
      console.log("onCancel", model);
    },
    items: [
      {
        type: "form",
        id: "newForm",
        default: {},
        items: [
          {
            type: "picker",
            id: "store",
            label: "STORE.NAME",
            placeholder: "STORE.NAME",
            mandatory: true,
            showSearch: true,
            optionFilterProp: "children",
            filterOption: (input: any, option: any) =>
              input &&
              option &&
              option.label &&
              option.label.toLowerCase().includes(input.toLowerCase()),
            optionsMap: {
              collection: "store",
              valueKey: "_id",
              labelKey: "name",
            },
          },
          {
            type: "picker",
            id: "inventory",
            label: "INVENTORY.NAME",
            placeholder: "INVENTORY.NAME",
            mandatory: true,
            showSearch: true,
            optionFilterProp: "children",
            filterOption: (input: any, option: any) =>
              input &&
              option &&
              option.label &&
              option.label.toLowerCase().includes(input.toLowerCase()),
            optionsMap: {
              collection: "inventory",
              valueKey: "_id",
              labelKey: "name",
            },
          },
          {
            type: "number",
            id: "tMinQT",
            label: "INVENTORYSTORE.TMINQT",
            placeholder: "INVENTORYSTORE.TMINQT",
            mandatory: true,
          },
          {
            type: "number",
            id: "minBX",
            label: "INVENTORYSTORE.MINBX",
            placeholder: "INVENTORYSTORE.MINBX",
            mandatory: true,
          },
          {
            type: "number",
            id: "tMaxQT",
            label: "INVENTORYSTORE.TMAXQT",
            placeholder: "INVENTORYSTORE.TMAXQT",
            mandatory: true,
          },
          {
            type: "number",
            id: "maxBX",
            label: "INVENTORYSTORE.MAXBX",
            placeholder: "INVENTORYSTORE.MAXBX",
            mandatory: true,
          },
        ],
        onFinish,
        onFinishFailed,
      },
    ],
  },
};

export { template };
