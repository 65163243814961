import { Typography } from "antd";
import { BaseComponent } from "./baseComponent";

export class Label extends BaseComponent {
  constructor() {
    super('label');
  }
  public renderComponent(item: any, pipe: any): any {
    const {
      label
    } = item;
    return <Typography><Typography.Paragraph>{pipe.t(label)}</Typography.Paragraph></Typography>
  }
};
