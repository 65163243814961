const template = {
  id: 'dashboard',
  collection: 'dashboard',
  view: 'dashboard',
  template: {
    type: 'space',
    cssClass: 'space-padding full-width',
    items: [{
      type: 'row',
      items: [{
        type: 'col',
        span: 24,
        items: [{
          type: 'label',
          label: 'DASHBOARD.WELCOME',
        }]
      }]
    }, {
      type: 'row',
      items: [{
        type: 'col',
        span: 24,
        items: [{
          type: 'label',
          label: 'DASHBOARD.TIMELINE',
        }]
      }, {
        type: 'col',
        span: 12,
        items: [{
          id: 'records',
          type: 'timeline',
        }]
      }]
    }]
  }
}

export {
  template
}