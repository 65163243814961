import { isFunction } from "lodash";
import { Store } from "../collections/store";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const onFinish = async (values: any, pipe: any) => {
  const { t, handleDialogConfirm, currentCollection, auth } = pipe;
  const updateModel = values;
  console.log('Received values of form: ', updateModel);
  const { mode } = currentCollection;
  const storeSvc = new Store(auth);
  try {
    if (mode === 'new') {
      await storeSvc.create(updateModel);
    } else {
      await storeSvc.update(mode, updateModel);
    }
  } catch (error: any) {
    showToast({
      type: 'error',
      title: 'ERROR.TITLE',
      description: (error as ApiError).getMessage()
    }, t)
  } finally {
    if (isFunction(handleDialogConfirm)) {
      handleDialogConfirm(pipe);
    }
  }
};

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

const template = {
  id: 'storeEdit',
  collection: 'store',
  view: 'edit',
  template: {
    type: 'dialog',
    title: 'STORE.NEW',
    closable: false,
    onOK: ({ model }: any) => {
      console.log('onOK', model);
    },
    onCancel: ({ model }: any) => {
      console.log('onCancel', model);
    },
    items: [{
      type: 'form',
      id: 'newForm',
      default: {},
      items: [{
        type: 'input',
        id: 'name',
        label: 'STORE.NAME',
        placeholder: 'STORE.NAME',
        mandatory: true,
      }, {
        type: 'input',
        id: 'customerLoc',
        label: 'STORE.CUSTOMER_LOC',
        placeholder: 'STORE.CUSTOMER_LOC',
        mandatory: true,
      }, {
        type: 'input',
        id: 'shippingLoc',
        label: 'STORE.SHIPPING_LOC',
        placeholder: 'STORE.SHIPPING_LOC',
        mandatory: true,
      }, {
        type: 'input',
        id: 'asnBuyerLocEAN',
        label: 'STORE.BUYER_CODE_ASN',
        placeholder: 'STORE.BUYER_CODE_ASN',
        mandatory: true,
      }, {
        type: 'input',
        id: 'ppoBuyerLocEAN',
        label: 'STORE.BUYER_CODE_PPO',
        placeholder: 'STORE.BUYER_CODE_PPO',
        mandatory: true,
      }, {
        type: 'number',
        id: 'ppoweek',
        label: 'STORE.PPOWEEK',
        placeholder: 'STORE.PPOWEEK',
        mandatory: true,
      }],
      onFinish,
      onFinishFailed,
    }]
  }
}

export {
  template
}