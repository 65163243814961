import { Button } from "antd";
import { get } from "lodash";
import { BaseComponent } from "./baseComponent";

export class ButtonComponent extends BaseComponent {
  constructor() {
    super('button');
  }
  public renderComponent(item: any, pipe: any): any {
    const {
      id, type, subType, htmlType, cssClass, label, onClick, icon, showLoading = false,
      ...otherProps
    } = item;
    return <Button key={id} type={ subType } loading={showLoading && get(pipe, ['loadings', id])} icon={pipe.renderComponent(icon, pipe)} className={ cssClass } htmlType={ htmlType } onClick={(event: any) => onClick && onClick({ ...event, ...pipe })} {...otherProps}>{pipe.t(label)}</Button>
  }
};
