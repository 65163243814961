import { read, utils } from "xlsx";
import { Collection } from "../services/collection";
import { post } from "../services/request";
import { template as table } from "../templates/tp-inventory";
import { template as edit } from "../templates/tp-inventory-edit";
import { template as view } from "../templates/tp-inventory-view";

export class Inventory extends Collection {
  constructor(auth?: any) {
    super(
      "inventory",
      {
        table,
        edit,
        view,
      },
      auth
    );
  }
  public import = async (inventories: any) =>
    post("inventory/import", { inventories });
  public importGTINs = async (gtins: any) =>
    post("inventory/import-gtins", { gtins });
  public importBatchs = async (batchs: any) =>
    post("inventory/import-batchs", { batchs });
  public convertXlsxToJson = async (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target?.result;
        const workbook = read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = utils.sheet_to_json(worksheet);
        resolve(json);
      };
      reader.readAsArrayBuffer(file);
    });
  };
}
