import { Inventorystore } from "../collections/inventorystore";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const template = {
  id: 'inventorystoreTable',
  collection: 'inventorystore',
  view: 'table',
  template: {
    type: 'space',
    items: [{
      type: 'space',
      cssClass: 'space-padding',
      direction: 'horizontal',
      items: [{
        id: 'newInventorystore',
        type: 'button',
        subType: 'primary',
        shape: 'round',
        icon: {
          type: 'icon',
          subType: 'plus',
          theme: 'outlined'
        },
        label: 'GENERAL.CREATE',
        onClick: ({ openCollection }: any) => {
          if (openCollection) {
            openCollection('inventorystore', 'edit', 'new');
          }
        }
      }, {
        id: 'importData',
        type: 'upload',
        subType: 'primary',
        shape: 'round',
        icon: {
          type: 'icon',
          subType: 'cloud-upload',
          theme: 'outlined'
        },
        label: 'GENERAL.IMPORT_EXCEL',
        customRequest: async (options: any, pipe: any) => {
          const { setLoadings, refreshTable } = pipe;
          try {
            setLoadings((prevLoadings: any) => ({ ...prevLoadings, importData: true }))
            const file = options.file as File;
            const inventoryStoreSvc = new Inventorystore();
            const json = await inventoryStoreSvc.convertXlsxToJson(file);
            const result = await inventoryStoreSvc.import(json);
            showToast({
              type: 'success',
              title: 'GENERAL.SUCCESS',
              description: `Import success.`,
            }, pipe.t);
            options.onSuccess(result);
          } catch (error) {
            showToast({
              type: 'error',
              title: 'ERROR.TITLE',
              description: (error as ApiError).getMessage()
            }, pipe.t);
            options.onError(error);
          } finally {
            setLoadings((prevLoadings: any) => ({ ...prevLoadings, importData: false }))
            refreshTable();
          }
        },
      }, ],
    }, {
      id: 'inventorystore',
      type: 'table',
      rowKey: '_id',
      columns: [{
        title: 'STORE.NAME',
        dataIndex: 'store',
        key: 'store',
        render: (data: any) => {
          return data && data.name
        }
      },{
        title: 'INVENTORY.NAME',
        dataIndex: 'inventory',
        key: 'inventory',
        render: (data: any) => {
          return data && data.name
        }
      },
      {
        title: 'INVENTORYSTORE.TMINQT',
        dataIndex: 'tMinQT',
        key: 'tMinQT',
      },
      {
        title: 'INVENTORYSTORE.MINBX',
        dataIndex: 'minBX',
        key: 'minBX',
      },
      {
        title: 'INVENTORYSTORE.TMAXQT',
        dataIndex: 'tMaxQT',
        key: 'tMaxQT',
      },
      {
        title: 'INVENTORYSTORE.MAXBX',
        dataIndex: 'maxBX',
        key: 'maxBX',
      }],
      dataType: {
        tMinQT: {
          type: 'number'
        },
        minBX: {
          type: 'number'
        },
        tMaxQT: {
          type: 'number'
        },
        maxBX: {
          type: 'number'
        },
      }
    }]
  }
}

export {
  template
}