import axios from 'axios';
import { join, map } from 'lodash';
import Config from '../config.json';
import { ApiError } from '../domains/error';

function getRootUrl() {
  return `${Config.host}:${Config.port}/${Config.apiPath}`;
}

async function request(promise: Promise<any>) {
  try {
    const { data, status } = await promise;
    if (status !== 200 ) {
      throw new ApiError();
    }
    return data;
  } catch (error) {
    throw new ApiError(error);
  }
}

export async function post(url: string, params: any) {
  return request(axios.post(`${getRootUrl()}/${url}`, params));
}

function prepareQueryString(url: string, params: any = {}) {
  let urlStr = `${getRootUrl()}/${url}`;
  if (!params) {
    return urlStr;
  }
  const {
    date, ...otherParams
  } = params;
  const query = map(otherParams, (value, key) => `${key}=${value}`);
  const queryStr = join(query, '&') || '';
  if (date) {
    urlStr = `${urlStr}/${date}`;
  }
  if (queryStr) {
    urlStr = `${urlStr}?${queryStr}`;
  }
  return urlStr;
}

export async function get(url: string, params?: any) {
  return request(axios.get(prepareQueryString(url, params)));
}

export async function put(url: string, id: string, row: any) {
  return request(axios.put(`${getRootUrl()}/${url}/${id}`, row));
}

export async function deleted(url: string, id: string) {
  return request(axios.delete(`${getRootUrl()}/${url}/${id}`));
}

export function upload(url: string, file: any) {
  const formData = new FormData();
  formData.append('file', file);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return request(axios.post(`${getRootUrl()}/${url}`, formData, config));
}
