import { notification } from 'antd';
import { get } from 'lodash';

const showToast = ({ type, title, description }: any, t: any) => {
  get(notification, type)({
    message: t(title),
    description: t(description),
  });
};

export {
  showToast
}