const template = {
  id: 'asnView',
  collection: 'asn',
  view: 'view',
  template: {
    type: 'dialog',
    title: 'ASN.VIEW',
    closable: true,
    hideFooter: true,
    items: [{
      type: 'desc',
      id: 'newView',
      bordered: true,
      layout: 'vertical',
      items: [{
        type: 'label',
        id: 'reportNum',
        label: 'INVRPT.REPORT_NUM',
      }, {
        type: 'label',
        id: 'poNum',
        label: 'PO.PO_NUM',
      }, {
        type: 'label',
        label: 'ASN.DELIVERY_PARTY',
        id: 'deliveryPartyEAN',
      }, {
        type: 'label',
        id: 'ssccNum',
        label: 'ASN.SSCC',
      },  {
        type: 'label',
        id: 'messageDate',
        label: 'PO.MSG_DATE',
      }, {
        type: 'label',
        id: 'despatchDate',
        label: 'ASN.DESPATCH_DATE',
        format: 'YYYY-MM-DD',
      }, {
        type: 'label',
        id: 'despatchNoteNum',
        label: 'ASN.DESPATCH_NOTE_NUM',
      }],
    }, {
      type: 'table',
      rowKey: 'lineNum',
      canDelete: false,
      canEdit: false,
      canView: false,
      columns: [{
        title: "PO.GTIN",
        dataIndex: "itemEAN",
        key: "itemEAN",
      },
      {
        title: "ASN.BATCH_NUM",
        dataIndex: "batchNum",
        key: "batchNum",
      },
      {
        title: "ASN.REF_DATE",
        dataIndex: "refDate",
        key: "refDate",
      },
      {
        title: "PO.MANUFACTURERS",
        dataIndex: "manufacturers",
        key: "manufacturers",
        render: (data: any) => {
          return (
            data &&
            data.map(({ country, name }: any) => (
              <span>
                {country}&nbsp;{name ? `:${name}` : ""}
                <br />
              </span>
            ))
          );
        },
      },
      {
        title: "PO.QUANTITY",
        dataIndex: "quantity",
        key: "quantity",
        render: (data: any) => {
          return (
            data &&
            data.map(({ quantity }: any) => <span>{quantity}</span>)
          );
        },
      },],
      id: 'line',
    }]
  }
}

export {
  template
}