const template = {
  id: 'locationView',
  collection: 'location',
  view: 'view',
  template: {
    type: 'dialog',
    title: 'LOCATION.VIEW',
    closable: true,
    hideFooter: true,
    items: [{
      type: 'desc',
      id: 'newView',
      bordered: true,
      layout: 'vertical',
      items: [{
        type: 'label',
        id: 'gln',
        label: 'LOCATION.GLN',
      }, {
        type: 'label',
        id: 'code',
        label: 'LOCATION.CODE',
      }, {
        type: 'label',
        id: 'addr1',
        label: 'LOCATION.ADDR1',
      }, {
        type: 'label',
        id: 'addr2',
        label: 'LOCATION.ADDR2',
      }, {
        type: 'label',
        id: 'addr3',
        label: 'LOCATION.ADDR3',
      }, {
        type: 'label',
        id: 'addr4',
        label: 'LOCATION.ADDR4',
      }, {
        type: 'label',
        id: 'addr5',
        label: 'LOCATION.ADDR5',
      }, {
        type: 'label',
        id: 'country',
        label: 'LOCATION.COUNTRY',
      }, {
        type: 'label',
        id: 'contact',
        label: 'LOCATION.CONTACT_PERSON',
      }, {
        type: 'label',
        id: 'phone',
        label: 'LOCATION.PHONE',
      }],
    }]
  }
}

export {
  template
}