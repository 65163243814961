import { sumBy } from "lodash";

const template = {
  id: 'invoiceView',
  collection: 'invoice',
  view: 'view',
  template: {
    type: 'dialog',
    title: 'INVOICE.VIEW',
    closable: true,
    hideFooter: true,
    items: [{
      type: 'desc',
      id: 'newView',
      bordered: true,
      layout: 'vertical',
      items: [{
        type: 'label',
        id: 'reportNum',
        label: 'INVRPT.REPORT_NUM',
      }, {
        type: 'label',
        id: 'poNum',
        label: 'PO.PO_NUM',
      }, {
        type: 'label',
        label: 'PO.INVOICEE',
        id: 'invoiceeEAN',
      }, {
        type: 'label',
        id: 'messageDate',
        label: 'PO.MSG_DATE',
        format: 'YYYY-MM-DD',
      }, {
        type: 'label',
        id: 'currency',
        label: 'PO.CURRENCY',
      }, {
        type: 'label',
        id: 'totalValOfQuantityAtLineLvl',
        label: 'ASN.TOTAL_QUANTITY',
      }, {
        type: 'label',
        id: 'totalInvoicedAmt',
        label: 'INVOICE.TOTAL',
      }],
    }, {
      type: 'table',
      rowKey: 'lineNum',
      canDelete: false,
      canEdit: false,
      canView: false,
      columns: [{
        title: "PO.GTIN",
        dataIndex: "itemEAN",
        key: "itemEAN",
      },
      {
        title: "INVRPT.STORE_LOC_NUM",
        dataIndex: "locationCode",
        key: "locationCode",
      },
      {
        title: "PO.PRICE",
        dataIndex: "price",
        key: "price",
      },
      {
        title: "PO.QUANTITY",
        dataIndex: "quantity",
        key: "quantity",
        render: (data: any) => {
          return data && <div>{sumBy(data, "quantity")}</div>;
        },
      },
      {
        title: "INVOICE.TOTAL",
        dataIndex: "totalAmt",
        key: "totalAmt",
      },],
      id: 'line',
      expandable: {
        rowKey: "notes",
        title: "PO.QUANTITY_NOTE",
        transform: {
          type: {
            optionsKey: "QUANTITY_CODE_MAPPING",
          },
        },
        columns: [
          {
            title: "PO.TYPE",
            dataIndex: "type",
            key: "type",
          },
        ],
        id: "quantity",
      },
    }]
  }
}

export {
  template
}