import { Collection } from "../services/collection";
import { post } from "../services/request";
import { template as table } from '../templates/tp-asn';
import { template as edit } from '../templates/tp-asn-edit';
import { template as view } from '../templates/tp-asn-view';

export class Asn extends Collection {
  constructor(auth?: any) {
    super('asn', {
      table,
      edit,
      view
    }, auth);
  }
  public upload = async (reportNum: string) => post(`upload/${reportNum}`, {});
}