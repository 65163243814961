import { Location } from "../collections/location";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const template = {
  id: "locationTable",
  collection: "location",
  view: "table",
  template: {
    type: "space",
    items: [
      {
        type: "space",
        cssClass: "space-padding",
        direction: "horizontal",
        items: [
          {
            id: "newStore",
            type: "button",
            subType: "primary",
            shape: "round",
            icon: {
              type: "icon",
              subType: "plus",
              theme: "outlined",
            },
            label: "GENERAL.CREATE",
            onClick: ({ openCollection }: any) => {
              if (openCollection) {
                openCollection("location", "edit", "new");
              }
            },
          },
          {
            id: "importData",
            type: "upload",
            subType: "primary",
            shape: "round",
            icon: {
              type: "icon",
              subType: "cloud-upload",
              theme: "outlined",
            },
            label: "GENERAL.IMPORT_EXCEL",
            customRequest: async (options: any, pipe: any) => {
              const { setLoadings, refreshTable } = pipe;
              try {
                setLoadings((prevLoadings: any) => ({
                  ...prevLoadings,
                  importData: true,
                }));
                const file = options.file as File;
                const locationSvc = new Location();
                const json = await locationSvc.convertXlsxToJson(file);
                const result = await locationSvc.import(json);
                showToast(
                  {
                    type: "success",
                    title: "GENERAL.SUCCESS",
                    description: `Import success.`,
                  },
                  pipe.t
                );
                options.onSuccess(result);
              } catch (error) {
                showToast(
                  {
                    type: "error",
                    title: "ERROR.TITLE",
                    description: (error as ApiError).getMessage(),
                  },
                  pipe.t
                );
                options.onError(error);
              } finally {
                setLoadings((prevLoadings: any) => ({
                  ...prevLoadings,
                  importData: false,
                }));
                refreshTable();
              }
            },
          },
        ],
      },
      {
        id: "location",
        type: "table",
        rowKey: "_id",
        canDelete: false,
        searchable: true,
        onSearch: (value: string, pipe: any) => {
          const { setFilters, setKeyword, refreshTable, handlePageChange } = pipe;
          setKeyword(value);
          setFilters([
            "gln",
            "code",
            "addr1",
            "addr2",
            "addr3",
            "addr4",
            "addr5",
            "contact",
            "phone",
          ]);
          handlePageChange({ page: 1 });
          refreshTable();
        },
        columns: [
          {
            title: "LOCATION.GLN",
            dataIndex: "gln",
            key: "gln",
          },
          {
            title: "LOCATION.CODE",
            dataIndex: "code",
            key: "code",
          },
          {
            title: "LOCATION.ADDR1",
            dataIndex: "addr1",
            key: "addr1",
          },
          {
            title: "LOCATION.ADDR2",
            dataIndex: "addr2",
            key: "addr2",
          },
          {
            title: "LOCATION.ADDR3",
            dataIndex: "addr3",
            key: "addr3",
          },
          {
            title: "LOCATION.ADDR4",
            dataIndex: "addr4",
            key: "addr4",
          },
          {
            title: "LOCATION.ADDR5",
            dataIndex: "addr5",
            key: "addr5",
          },
          {
            title: "LOCATION.COUNTRY",
            dataIndex: "country",
            key: "country",
          },
          {
            title: "LOCATION.CONTACT_PERSON",
            dataIndex: "contact",
            key: "contact",
          },
        ],
      },
    ],
  },
};

export { template };
