import { Inventory } from "./inventory";
import { Inventorystore } from "./inventorystore";
import { Invrpt } from "./invrpt";
import { Po } from "./po";
import { Pochange } from "./pochange";
import { Poresponse } from "./poresponse";
import { Proposedpo } from "./proposedpo";
import { Asn } from "./asn";
import { Invoice } from "./invoice";
import { Store } from "./store";
import { Dashboard } from "./dashboard";
import { Location } from "./location";
export interface Constructable<T> {
  new(...args: any) : T;
}

const collections = {
  Asn,
  Dashboard,
  Inventory,
  Inventorystore,
  Invoice,
  Invrpt,
  Location,
  Po,
  Pochange,
  Poresponse,
  Proposedpo,
  Store
};

export {
  collections
}