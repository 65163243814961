import {
  Form, Button, Space, Modal, Menu, Card, 
} from "antd";
import { get, map, each, set, isFunction, isArray, merge } from "lodash";
import moment from "moment";
import { components } from "../components/components";
import { CollectionView } from "../pages/collections/view";

const onFinish = (values: any, pipe: any) => {
  console.log('Received values of form: ', values);
};

const onFinishFailed = (errorInfo: any, pipe: any) => {
  console.log('Failed:', errorInfo);
};

const onFieldsChange = (changedFields: any, allFields: any) => {
  console.log('onFieldsChange: ', changedFields, allFields);
}

const onValuesChange = (changedValues: any, allValues: any) => {
  console.log('onValuesChange: ', changedValues, allValues);
}

const transformData = (model: any, template: any): any | any[] => {
  each(template.items, ({ type, id, items }) => {
    if (['date', 'datetime'].includes(type) && get(model, id)) {
      set(model, id, moment(get(model, id)));
    } else if (items && isArray(get(model, id)) && template.type === 'list') {
      each(get(model, id), subModel => {
        transformData(subModel, { type, id, items })
      });
    }
  });
}
const renderForm = (item: any, pipe: any) => {
  const initialValues = merge(pipe.model, get(item, 'default'));
  transformData(initialValues, item);
  return <Form
    name={get(item, 'id')}
    className={get(item, 'cssClass')}
    initialValues={initialValues}
    layout={get(item, 'layout', 'vertical')}
    onFinish={(values: any) => get(item, 'onFinish', onFinish)(values, pipe)}
    onFinishFailed={(errorInfo: any) => get(item, 'onFinishFailed', onFinishFailed)(errorInfo, pipe)}
    onFieldsChange={(changedFields: any, allFields: any) => get(item, 'onFieldsChange', onFieldsChange)(changedFields, allFields)}
    onValuesChange={(changedValues: any, allValues: any) =>get(item, 'onValuesChange', onValuesChange)(changedValues, allValues)}
  >
    {map(get(item, 'items', []), (subItem) => renderFormSubItem(subItem, pipe))}
  </Form>
}

const renderFormSubItem = (item: any, pipe: any) => (
  <Form.Item key={get(item, 'id')} name={get(item, 'id')} label={get(item, 'type') !== 'button' && pipe.t(get(item, 'label')) } initialValue={get(item, 'initialValue')} rules={get(item, 'mandatory', false) && [{ required: get(item, 'mandatory'), message: pipe.t(get(item, 'mandatoryMessage', 'ERROR.MISS_VALUE')) }]}>
    { renderComponent(item, pipe) }
  </Form.Item>
)

const renderSpace = (item: any, pipe: any) => (
  <Space direction={get(item, 'direction', 'vertical')} size={get(item, 'size', 'small')} align={get(item, 'align')} className={get(item, 'cssClass', '')}>
    {map(get(item, 'items', []), item => renderComponent(item, pipe))}
  </Space>
)

const transformDateFields = (array: Array<any>, items: Array<any>) => {
  each(items, ({ type, id }) => {
    each(array, obj => {
      if (obj[id] && type === 'date') {
        obj[id] = moment(obj[id]);
      }
    })
  })
  return array;
}

const renderList = (item: any, pipe: any) => {
  const { items, initialValues, addButtonLabel = 'GENERAL.ADD', id, mandatory, label } = item;
  const initVals = transformDateFields(get(pipe.model, id) || initialValues, items);
  return <Form.List name={id} initialValue={initVals || (mandatory ? [{}] : [])}>
    {(fields, { add, remove }) => (
      <>
        {fields.map((field, idx) => (
          <Card key={`${field.name}#${idx}`} title={`${pipe.t(label)} ${idx+1}`} style={{ display: 'flex' }} extra={renderComponent({
            type: 'button',
            subType: 'text',
            icon: {
              type: 'icon',
              subType: 'delete',
              theme: 'outlined',
            },
            onClick: () => remove(idx)
          }, pipe)}>
            {map(map(items, itm => ({ ...itm, id: [field.name, itm.id], parentId: id })), (subItem) => renderFormSubItem(subItem, pipe))}
          </Card>
        ))}
        <Form.Item>
          {renderComponent({
            type: 'button',
            label: addButtonLabel,
            onClick:() => add(),
            block: true,
            icon: {
              type: 'icon',
              subType: 'plus',
              theme: 'outlined'
            }
          }, pipe)}
        </Form.Item>
      </>
    )}
  </Form.List>
}

const renderOptions = (optionsMap: any, pipe: any) => {
  const { collection, labelKey = 'name', valueKey = '_id', showValue = false, optionsKey, descKey } = optionsMap;
  if (collection) {
    const options = map(get(pipe, ['optionsMap', collection, 'options']), (opt) => ({ ...opt, label: (showValue ? `${get(opt, labelKey)} (${get(opt, valueKey)})` : get(opt, labelKey)) + (descKey ? ` - ${get(opt, descKey)}` : ''), value: get(opt, valueKey) }));
    return options;
  } else if (optionsKey) {
    return get(pipe, ['optionsMap', optionsKey, 'options']);
  }
}

const renderTransform = (transform: any, item: any, pipe: any) => {
  each(transform, ({ optionsKey: transformedOptionsKey, collection, showValue, labelKey, valueKey }, key) => {
    if (transformedOptionsKey) {
      set(item, ['options', key], get(pipe, ['optionsMap', transformedOptionsKey, 'options']));
    }
    if (collection) {
      const options = map(get(pipe, ['optionsMap', collection, 'options']), (opt) => ({ ...opt, label: showValue ? `${get(opt, labelKey)} (${get(opt, valueKey)})` : get(opt, labelKey), value: get(opt, valueKey) }));
      set(item, ['options', key], options);
    }
  })
}

const renderComponent = (item: any, pipe: any) => {
  if (!get(item, 'type')) {
    return <></>;
  }
  const {
    type, optionsMap, transform,
  } = item;
  if (optionsMap) {
    item.options = renderOptions(optionsMap, pipe);
  }
  if (transform) {
    renderTransform(transform, item, pipe);
  }
  if (get(components, type)) {
    return get(components, type).renderComponent(item, { ...pipe, renderComponent });
  }
  switch (type) {
    case 'space':
    case 'section':
      return renderSpace(item, pipe);
    case 'form':
      return renderForm(item, pipe);
    case 'list':
      return renderList(item, pipe);
  }
}

const renderMenu = ({ items, mode, theme, style, defaultKey, onClick }: any, pipe: any) => {
  return <Menu
    style={style}
    defaultSelectedKeys={[defaultKey]}
    defaultOpenKeys={[defaultKey]}
    mode={mode}
    theme={theme}
    items={map(items, ({ id, icon, items, label }) => ({
      key: id,
      icon: renderComponent(icon, pipe),
      children: items,
      label: pipe.t(label),
    }))}
    onClick={(event) => onClick(event, pipe)}
    selectedKeys={[pipe.collection]}
  />
}

const renderDialog = (item: any, pipe: any) => {
  const { isDialogShown, handleDialogConfirm, handleDialogCancel, t } = pipe;
  const { onOK, onCancel, title, centered = true, width = '100%', fullscreen = true, items, okText = 'GENERAL.SAVE', cancelText = 'GENERAL.CANCEL', hideFooter, ...otherProps } = item;
  return <Modal
    title={t(title)}
    centered={centered}
    visible={isDialogShown}
    className={fullscreen ? 'fullscreen': ''}
    onOk={() => {
      if (isFunction(onOK)) {
        onOK(pipe);
      }
      if (isFunction(handleDialogConfirm)) {
        handleDialogConfirm(pipe);
      }
    }}
    onCancel={() => {
      if (isFunction(onCancel)) {
        onCancel(pipe);
      }
      if (isFunction(handleDialogCancel)) {
        handleDialogCancel(pipe);
      }
    }}
    width={width}
    footer={items && !hideFooter && get(items, '0.id') && [
      <Button onClick={() => {
        if (isFunction(onCancel)) {
          onCancel(pipe);
        }
        if (isFunction(handleDialogCancel)) {
          handleDialogCancel(pipe);
        }
      }}>
          {t(cancelText)}
      </Button>,
      <Button type='primary' form={get(items, '0.id')} key="submit" htmlType="submit">
          {t(okText)}
      </Button>
    ]}
    {...otherProps}
  >
    {
      renderSpace({
        type: 'space',
        cssClass: 'full-width',
        direction: 'horizontal',
        align: 'start',
        items
      }, pipe)
    }
  </Modal>
}

const Templates = ({ template, pipe }: any): JSX.Element => {
  const type: string = get(template, 'template.type');
  const item = get(template, 'template');
  const renderers: any = {
    dialog: renderDialog,
    menu: renderMenu,
  }
  return (renderers[type] || renderComponent)(item, pipe);
}

const CollectionPage = ({ name, view = 'table', mode, model, pipe }: any, children: any[]) => {
  return <CollectionView key={name} name={name} view={view} mode={mode} model={model} pipe={{ ...pipe, currentCollection: { name, view, mode } }}>{ children }</CollectionView>
}

export {
  Templates,
  CollectionPage,
}