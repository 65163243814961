const template = {
  id: 'storeView',
  collection: 'store',
  view: 'view',
  template: {
    type: 'dialog',
    title: 'STORE.VIEW',
    closable: true,
    hideFooter: true,
    items: [{
      type: 'desc',
      id: 'newView',
      bordered: true,
      layout: 'vertical',
      items: [{
        type: 'label',
        id: 'name',
        label: 'STORE.NAME',
      }, {
        type: 'label',
        id: 'customerLoc',
        label: 'STORE.CUSTOMER_LOC',
      }, {
        type: 'label',
        id: 'shippingLoc',
        label: 'STORE.SHIPPING_LOC',
      }, {
        type: 'label',
        id: 'asnBuyerLocEAN',
        label: 'STORE.BUYER_CODE_ASN',
      }, {
        type: 'label',
        id: 'ppoBuyerLocEAN',
        label: 'STORE.BUYER_CODE_PPO',
      }, {
        type: 'label',
        id: 'ppoweek',
        label: 'STORE.PPOWEEK',
      }],
    }]
  }
}

export {
  template
}