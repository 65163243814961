import { get, isFunction, merge } from "lodash";
import { Invoice } from "../collections/invoice";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const onFinish = async (values: any, pipe: any) => {
  const { t, handleDialogConfirm, currentCollection, extraValue, auth } = pipe;
  const updateModel = merge({ ...values, messageDate: get(values, 'messageDate').format('YYYY-MM-DD')}, extraValue);
  console.log('Received values of form: ', updateModel);
  const { mode } = currentCollection;
  const invoiceSvc = new Invoice(auth);
  try {
    if (mode === 'new') {
      await invoiceSvc.create(updateModel);
    } else {
      await invoiceSvc.update(mode, updateModel);
    }
  } catch (error: any) {
    showToast({
      type: 'error',
      title: 'ERROR.TITLE',
      description: (error as ApiError).getMessage()
    }, t)
  } finally {
    if (isFunction(handleDialogConfirm)) {
      handleDialogConfirm(pipe);
    }
  }
};

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

const template = {
  id: 'invoiceEdit',
  collection: 'invoice',
  view: 'edit',
  template: {
    type: 'dialog',
    title: 'INVOICE.NEW',
    closable: false,
    onOK: ({ model }: any) => {
      console.log('onOK', model);
    },
    onCancel: ({ model }: any) => {
      console.log('onCancel', model);
    },
    items: [{
      type: 'form',
      id: 'newForm',
      default: {
        function: '16',
      },
      items: [{
        type: 'input',
        id: 'reportNum',
        label: 'INVRPT.REPORT_NUM',
        placeholder: 'INVRPT.REPORT_NUM',
        disabled: true,
      }, {
        type: 'input',
        id: 'poNum',
        label: 'PO.PO_NUM',
        placeholder: 'PO.PO_NUM',
        disabled: true,
      },  {
        type: 'input',
        id: 'invoiceeEAN',
        label: 'PO.INVOICEE',
        placeholder: 'PO.INVOICEE',
        disabled: true,
      }, {
        type: 'date',
        id: 'messageDate',
        label: 'PO.MSG_DATE',
        placeholder: 'PO.MSG_DATE',
        format: 'YYYY-MM-DD',
        mandatory: true,
      }, {
        type: 'input',
        id: 'currency',
        label: 'PO.CURRENCY',
        placeholder: 'PO.CURRENCY',
        disabled: true,
      }, {
        type: 'list',
        id: 'line',
        label: 'PROPOSEDPO.LINE',
        mandatory: true,
        items: [{
          type: 'picker',
          id: 'itemEAN',
          label: 'PO.GTIN',
          placeholder: 'PO.GTIN',
          disabled: true,
          optionsMap: {
            collection: 'inventory',
            valueKey: 'gtin',
            labelKey: 'name',
            showValue: true,
            extraValueKeys: [{
              id: 'haItemNum',
              valueKey: 'haItemCode'
            }]
          }
        }, {
          type: 'input',
          id: 'locationCode',
          label: 'INVRPT.STORE_LOC_NUM',
          placeholder: 'INVRPT.STORE_LOC_NUM',
          disabled: true,
        }, {
          type: 'input',
          id: 'price',
          label: 'PO.PRICE',
          placeholder: 'PO.PRICE',
          disabled: true,
        }, {
          type: 'input',
          id: 'contractNum',
          label: 'PO.CONTRACT_NUM',
          placeholder: 'PO.CONTRACT_NUM',
          disabled: true,
        }, {
          type: 'list',
          id: 'quantity',
          label: 'PO.QUANTITY',
          mandatory: true,
          items: [{
            type: 'number',
            id: 'quantity',
            label: 'PO.QUANTITY',
            placeholder: 'PO.QUANTITY',
            disabled: true,
          }, {
            type: 'picker',
            id: 'type',
            label: 'PO.TYPE',
            placeholder: 'PO.TYPE',
            disabled: true,
            initialValue: '47',
            optionsMap: {
              optionsKey: 'QUANTITY_CODE_MAPPING'
            }
          }]
        }]
      }],
      onFinish,
      onFinishFailed,
    }]
  }
}

export {
  template
}