import { Input } from "antd";
import { BaseComponent } from "./baseComponent";

export class Text extends BaseComponent {
  constructor() {
    super('text');
  }
  public renderComponent(item: any, pipe: any): any {
    const {
      id, type, prefix, suffix, placeholder, subType, cssClass, label,
      mandatory, mandatoryMessage, icon, ...otherProps
    } = item;
    return <Input key={id} prefix={pipe.renderComponent(icon, pipe)} suffix={pipe.renderComponent(suffix, pipe)} addonBefore={prefix && pipe.renderComponent(prefix, pipe)} addonAfter={suffix && pipe.renderComponent(suffix, pipe)} placeholder={ pipe.t(placeholder) } label={ pipe.t(label) } type={ subType } className={ cssClass } {...otherProps}/>
  }
};
