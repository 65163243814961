export class BaseComponent {
  private _name: string;
  constructor(name: string) {
    this._name = name;
  }

  public getComponentName(): string {
    return this._name;
  }

  public renderComponent(item: any, pipe: any): any {
  }
};
