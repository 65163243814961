import { TimePicker } from "antd";
import { BaseComponent } from "./baseComponent";

export class TimeField extends BaseComponent {
  constructor() {
    super('time');
  }
  public renderComponent(item: any, pipe: any): any {
    const {
      id, type, placeholder, cssClass, label,
      mandatory, mandatoryMessage,
      ...otherProps
    } = item;
    return <TimePicker key={id} placeholder={ pipe.t(placeholder) } label={ pipe.t(label) } className={ cssClass } {...otherProps}  />
  }
};
