import { get, isFunction, merge } from "lodash";
import { Proposedpo } from "../collections/proposedpo";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const onFinish = async (values: any, pipe: any) => {
  const { t, handleDialogConfirm, currentCollection, extraValue, auth } = pipe;
  const updateModel = merge({ ...values, messageDate: get(values, 'messageDate').format('YYYY-MM-DD'), despatchDate: get(values, 'despatchDate').format('YYYY-MM-DD') }, extraValue);
  console.log('Received values of form: ', updateModel);
  const { mode } = currentCollection;
  const proposedpoSvc = new Proposedpo(auth);
  try {
    if (mode === 'new') {
      await proposedpoSvc.create(updateModel);
    } else {
      await proposedpoSvc.update(mode, updateModel);
    }
  } catch (error: any) {
    showToast({
      type: 'error',
      title: 'ERROR.TITLE',
      description: (error as ApiError).getMessage()
    }, t)
  } finally {
    if (isFunction(handleDialogConfirm)) {
      handleDialogConfirm(pipe);
    }
  }
};

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

const template = {
  id: 'proposedpoEdit',
  collection: 'proposedpo',
  view: 'edit',
  template: {
    type: 'dialog',
    title: 'PROPOSEDPO.NEW',
    closable: false,
    onOK: ({ model }: any) => {
      console.log('onOK', model);
    },
    onCancel: ({ model }: any) => {
      console.log('onCancel', model);
    },
    items: [{
      type: 'form',
      id: 'newForm',
      default: {
        function: '16',
      },
      items: [{
        type: 'input',
        id: 'reportNum',
        label: 'INVRPT.REPORT_NUM',
        placeholder: 'INVRPT.REPORT_NUM',
        disabled: true,
      }, {
        type: 'picker',
        id: 'storeEAN',
        label: 'INVRPT.STORE_LOC_NUM',
        placeholder: 'INVRPT.STORE_LOC_NUM',
        mandatory: true,
        showSearch: true,
        optionFilterProp: "children",
        filterOption: (input: any, option: any) => input && option && option.value && option.value.toLowerCase().includes(input.toLowerCase()),
        optionsMap: {
          collection: 'store',
          valueKey: 'ppoBuyerLocEAN',
          labelKey: 'name',
          showValue: true,
          extraValueKeys: [{
            id: 'asnBuyerLocEAN',
            valueKey: 'asnBuyerLocEAN'
          }]
        }
      }, {
        type: 'date',
        id: 'messageDate',
        label: 'PO.MSG_DATE',
        placeholder: 'PO.MSG_DATE',
        format: 'YYYY-MM-DD',
        mandatory: true,
      }, {
        type: 'date',
        id: 'despatchDate',
        label: 'ASN.DESPATCH_DATE',
        placeholder: 'ASN.DESPATCH_DATE',
        format: 'YYYY-MM-DD',
        mandatory: true,
      }, {
        type: 'picker',
        id: 'function',
        label: 'POCHANGE.FUNC',
        placeholder: 'POCHANGE.FUNC',
        disabled: true,
        mandatory: true,
        optionsMap: {
          optionsKey: 'FUNC_CODE_MAPPING'
        }
      }, {
        type: 'list',
        id: 'line',
        label: 'PROPOSEDPO.LINE',
        mandatory: true,
        items: [{
          type: 'picker',
          id: 'itemEAN',
          label: 'PO.GTIN',
          placeholder: 'PO.GTIN',
          mandatory: true,
          showSearch: true,
          optionFilterProp: "children",
          filterOption: (input: any, option: any) => input && option && option.value && option.value.toLowerCase().includes(input.toLowerCase()),
          optionsMap: {
            collection: 'inventory',
            valueKey: 'gtin',
            labelKey: 'name',
            descKey: 'haItemCode',
            showValue: true,
            extraValueKeys: [{
              id: 'haItemNum',
              valueKey: 'haItemCode',
            }]
          }
        }, {
          type: 'list',
          id: 'quantity',
          label: 'PO.QUANTITY',
          mandatory: true,
          items: [{
            type: 'number',
            id: 'quantity',
            label: 'PO.QUANTITY',
            placeholder: 'PO.QUANTITY',
            mandatory: true,
          }, {
            type: 'picker',
            id: 'type',
            label: 'PO.TYPE',
            placeholder: 'PO.TYPE',
            mandatory: true,
            disabled: true,
            initialValue: '21',
            optionsMap: {
              optionsKey: 'QUANTITY_CODE_MAPPING'
            }
          }, {
            type: 'list',
            id: 'notes',
            label: 'PO.QUANTITY_NOTE',
            items: [{
              type: 'picker',
              label: 'PO.QUANTITY_NOTE_TYPE',
              id: 'type',
              optionsMap: {
                optionsKey: 'FTX_MAPPING'
              }
            }, {
              type: 'textarea',
              id: 'text',
              label: 'PO.QUANTITY_NOTE',
              placeholder: 'PO.QUANTITY_NOTE',
            }]
          }]
        }]
      }],
      onFinish,
      onFinishFailed,
    }]
  }
}

export {
  template
}