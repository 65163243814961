import { Collection } from "../services/collection";
import { get } from "../services/request";
import { template as table } from '../templates/tp-pochange';
import { template as view } from '../templates/tp-pochange-view';

export class Pochange extends Collection {
  constructor(auth?: any) {
    super('pochange', {
      table,
      view
    }, auth);
  }
  public fetch = async () => get('pochange/fetch');
}