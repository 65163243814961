import { set, sumBy } from "lodash";
import { Invoice } from "../collections/invoice";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const template = {
  id: "invoiceTable",
  collection: "invoice",
  view: "table",
  template: {
    type: "space",
    items: [
      {
        id: "invoice",
        type: "table",
        rowKey: "reportNum",
        canDelete: false,
        canEdit: ({ data }: any) => data.status !== 'UPLOADED',
        searchable: true,
        onSearch: (value: string, pipe: any) => {
          const { setFilters, setKeyword, refreshTable, handlePageChange } = pipe;
          setKeyword(value);
          setFilters([
            "reportNum",
            "poNum",
            "invoiceeEAN",
            "line.locationCode",
            "line.itemEAN",
            "line.batchNum",
          ]);
          handlePageChange({ page: 1 });
          refreshTable();
        },
        extraActions: [
          {
            id: "upload",
            icon: {
              type: "icon",
              subType: "cloud-upload",
              theme: "outlined",
            },
            hidden: ({ data }: any) => data.status === 'UPLOADED',
            showLoading: true,
            onClick: async (event: any, pipe: any) => {
              const { setLoadings, refreshTable } = pipe;
              const { originalRecord, index } = event;
              try {
                const { reportNum } = originalRecord || {};
                const invoiceSvc = new Invoice();
                setLoadings((prevLoadings: any) => {
                  const newLoadings = { ...prevLoadings };
                  set(newLoadings, ["upload", index], true);
                  return newLoadings;
                });
                const { status, data } = await invoiceSvc.upload(reportNum);
                if (status) {
                  showToast(
                    {
                      type: "success",
                      title: "GENERAL.SUCCESS",
                      description: `${status}`,
                    },
                    pipe.t
                  );
                  console.log(`${JSON.stringify(data)}`);
                }
              } catch (error) {
                showToast(
                  {
                    type: "error",
                    title: "ERROR.TITLE",
                    description: (error as ApiError).getMessage(),
                  },
                  pipe.t
                );
              } finally {
                setLoadings((prevLoadings: any) => {
                  const newLoadings = { ...prevLoadings };
                  set(newLoadings, ["upload", index], false);
                  return newLoadings;
                });
                refreshTable();
              }
            },
          },
        ],
        columns: [
          {
            title: "INVRPT.REPORT_NUM",
            dataIndex: "reportNum",
            key: "reportNum",
          },
          {
            title: "PO.PO_NUM",
            dataIndex: "poNum",
            key: "poNum",
          },
          {
            title: "PO.INVOICEE",
            dataIndex: "invoiceeEAN",
            key: "invoiceeEAN",
          },
          {
            title: "PO.MSG_DATE",
            dataIndex: "messageDate",
            key: "messageDate",
          },
          {
            title: "PO.CURRENCY",
            dataIndex: "currency",
            key: "currency",
          },
          {
            title: "ASN.TOTAL_QUANTITY",
            dataIndex: "totalValOfQuantityAtLineLvl",
            key: "totalValOfQuantityAtLineLvl",
          },
          {
            title: "INVOICE.TOTAL",
            dataIndex: "totalInvoicedAmt",
            key: "totalInvoicedAmt",
          },
          {
            title: "COMMON.STATUS",
            dataIndex: "status",
            key: "status",
          },
        ],
        dataType: {
          totalInvoicedAmt: {
            type: "currency",
          },
        },
        expandable: {
          rowKey: "lineNum",
          columns: [
            {
              title: "PO.GTIN",
              dataIndex: "itemEAN",
              key: "itemEAN",
            },
            {
              title: "INVRPT.STORE_LOC_NUM",
              dataIndex: "locationCode",
              key: "locationCode",
            },
            {
              title: "PO.PRICE",
              dataIndex: "price",
              key: "price",
            },
            {
              title: "PO.QUANTITY",
              dataIndex: "quantity",
              key: "quantity",
              render: (data: any) => {
                return data && <div>{sumBy(data, "quantity")}</div>;
              },
            },
            {
              title: "INVOICE.TOTAL",
              dataIndex: "totalAmt",
              key: "totalAmt",
            },
          ],
          id: "line",
          expandable: {
            rowKey: "notes",
            title: "PO.QUANTITY_NOTE",
            transform: {
              type: {
                optionsKey: "QUANTITY_CODE_MAPPING",
              },
            },
            columns: [
              {
                title: "PO.TYPE",
                dataIndex: "type",
                key: "type",
              },
            ],
            id: "quantity",
          },
        },
      },
    ],
  },
};

export { template };
