import { Select } from "antd";
import { each, first, get, isArray, set } from "lodash";
import { BaseComponent } from "./baseComponent";

export class Picker extends BaseComponent {
  constructor() {
    super('picker');
  }
  public renderComponent(item: any, pipe: any): any {
    const {
      id, parentId, type, placeholder, cssClass, label,
      mandatory, mandatoryMessage, options,
      ...otherProps
    } = item;
    return <Select key={id} placeholder={ pipe.t(placeholder) } options={options} onChange={(value, option)=>{
      if (get(item.optionsMap, 'extraValueKeys')) {
        each(get(item.optionsMap, 'extraValueKeys'), ({ id: targetId, valueKey }) => {
          set(pipe, parentId ? ['extraValue', parentId, isArray(id) ? first(id) : id, targetId] : ['extraValue', targetId], get(option, valueKey));
          set(pipe, ['model', targetId], get(option, valueKey));
        });
      }
    }} {...otherProps} ></Select>
  }
};
