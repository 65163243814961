import { read, utils } from "xlsx";
import { Collection } from "../services/collection";
import { post } from "../services/request";
import { template as table } from '../templates/tp-inventorystore';
import { template as edit } from '../templates/tp-inventorystore-edit';
import { template as view } from '../templates/tp-inventorystore-view';

export class Inventorystore extends Collection {
  constructor(auth?: any) {
    super('inventorystore', {
      table,
      edit,
      view
    }, auth);
  }
  public import = async (settings: any) => post('inventorystore/import', { settings });
  public check = async (storeEAN: string, gtin: string) => post('inventorystore/import', { storeEAN, gtin });
  public convertXlsxToJson = async (file: File) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = (e) => {
          const data = e.target?.result;
          const workbook = read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = utils.sheet_to_json(worksheet);
          resolve(json);
      };
      reader.readAsArrayBuffer(file);
    })
  }
}