import { Timeline } from "antd";
import { get, map } from "lodash";
import moment from "moment";
import { BaseComponent } from "./baseComponent";

export class TimelineComponent extends BaseComponent {
  constructor() {
    super('timeline');
  }
  public renderComponent(item: any, pipe: any): any {
    const {
      id, type, label, format, color, ...otherProps
    } = item;
    return <Timeline mode={'left'} {...otherProps}>
    {map(get(pipe.model, id, []), (row) => 
      <Timeline.Item label={moment(get(row, 'datetime')).format(format || 'YYYY-MM-DD HH:mm:ss')} color={color || 'blue'}>{get(row, 'desc')}</Timeline.Item>)}
    </Timeline>
  }
};
