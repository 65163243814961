import { Card } from "antd";
import { filter, find, first, get, groupBy, join, keys, map } from "lodash";
import moment from "moment";
import { Asn } from "../collections/asn";
import { Promise } from "bluebird";
import { showToast } from "../services/toast";
import { ApiError } from "../domains/error";
import { Pochange } from "../collections/pochange";

const template = {
  id: "pochangeTable",
  collection: "pochange",
  view: "table",
  template: {
    type: "space",
    items: [
      {
        type: "space",
        cssClass: "space-padding",
        items: [
          {
            id: "fetch",
            type: "button",
            subType: "primary",
            shape: "round",
            icon: {
              type: "icon",
              subType: "cloud-download",
              theme: "outlined",
            },
            label: "GENERAL.FETCH",
            showLoading: true,
            onClick: async (pipe: any) => {
              const { setLoadings, refreshTable } = pipe;
              try {
                const pochangeSvc = new Pochange();
                setLoadings((prevLoadings: any) => ({
                  ...prevLoadings,
                  fetch: true,
                }));
                const { numOfFiles, numOfMessages } = await pochangeSvc.fetch();

                showToast(
                  {
                    type: "success",
                    title: "GENERAL.SUCCESS",
                    description: `Number of files: ${numOfFiles}\nNumber of Messages: ${numOfMessages}`,
                  },
                  pipe.t
                );
              } catch (error) {
                showToast(
                  {
                    type: "error",
                    title: "ERROR.TITLE",
                    description: (error as ApiError).getMessage(),
                  },
                  pipe.t
                );
              } finally {
                setLoadings((prevLoadings: any) => ({
                  ...prevLoadings,
                  fetch: false,
                }));
                refreshTable();
              }
            },
          },
        ],
      },
      {
        id: "pochange",
        type: "table",
        rowKey: "reportNum",
        canDelete: false,
        canEdit: false,
        searchable: true,
        onSearch: (value: string, pipe: any) => {
          const { setFilters, setKeyword, refreshTable, handlePageChange } = pipe;
          setKeyword(value);
          setFilters([
            "reportNum",
            "invoiceeEAN",
            "line.locationCode",
            "line.itemEAN",
            "line.proposedPONum",
          ]);
          handlePageChange({ page: 1 });
          refreshTable();
        },
        extraActions: [
          {
            icon: {
              type: "icon",
              subType: "send",
              theme: "outlined",
            },
            id: "send",
            hidden: ({ data }: any) => data?.refs?.por?.length > 0,
            onClick: (event: any, { openCollection }: any) => {
              console.log(event);
              if (openCollection) {
                const { originalRecord } = event;
                const {
                  reportNum: poNum,
                  invoiceeEAN,
                  poChangeNum,
                } = originalRecord || {};
                openCollection("poresponse", "edit", "new", {
                  poNum,
                  invoiceeEAN,
                  poChangeNum,
                  messageDate: moment().format("YYYY-MM-DD"),
                });
              }
            },
          },
          {
            icon: {
              type: "icon",
              subType: "dropbox",
              theme: "outlined",
            },
            id: "asn",
            onClick: async (
              event: any,
              { openCollection, optionsMap, auth }: any
            ) => {
              if (openCollection) {
                const { originalRecord } = event;
                const { reportNum: poNum, line } = originalRecord || {};
                const asnSvc = new Asn(auth);
                const locations = groupBy(line, "locationCode");
                let count = 0;
                const asns: any[] = [];
                await Promise.mapSeries(
                  keys(locations),
                  async (deliveryPartyEAN: string) => {
                    const updateModel = {
                      poNum,
                      deliveryPartyEAN,
                      messageDate: moment().format("YYYY-MM-DD"),
                      line: map(
                        filter(
                          locations[deliveryPartyEAN],
                          ({ quantity }) =>
                            get(first(quantity), "quantity", 0) > 0
                        ),
                        (ln) => {
                          const { itemEAN } = ln;
                          const inv =
                            find(
                              optionsMap.inventory.options,
                              ({ gtin }) => gtin === itemEAN
                            ) || {};
                          const manufacturers = [
                            {
                              name: inv.manufacturer,
                              country: inv.country,
                            },
                          ];
                          return {
                            ...ln,
                            poLineNum: ln.lineNum,
                            manufacturers,
                            batchNum: get(inv, "lots.0.lotNo", ""),
                            refDate: get(inv, "lots.0.expiryDate", ""),
                            quantity: map(ln.quantity, ({ quantity }) => ({
                              quantity,
                              type: "12",
                            })),
                          };
                        }
                      ),
                    };
                    const asn = await asnSvc.create(updateModel);
                    asns.push(asn);
                    count += 1;
                  }
                );
                openCollection("asn", "edit", asns[0]._id, asns[0]);
                console.log(`Created ${count} ASN(s): ${join(asns, ", ")}`);
              }
            },
          },
          {
            icon: {
              type: "icon",
              subType: "dollar",
              theme: "outlined",
            },
            id: "invoice",
            onClick: (event: any, { openCollection }: any) => {
              if (openCollection) {
                const { originalRecord } = event;
                const {
                  reportNum: poNum,
                  invoiceeEAN,
                  currency,
                  line,
                } = originalRecord || {};
                openCollection("invoice", "edit", "new", {
                  poNum,
                  invoiceeEAN,
                  currency,
                  messageDate: moment().format("YYYY-MM-DD"),
                  line: map(
                    filter(
                      line,
                      ({ quantity }) => get(first(quantity), "quantity", 0) > 0
                    ),
                    ({
                      lineNum,
                      itemEAN,
                      haItemCode,
                      haItemNumCode,
                      locationCode,
                      price,
                      quantity,
                      contractNum,
                      proposedPONum,
                    }) => ({
                      poLineNum: lineNum,
                      itemEAN,
                      haItemCode,
                      haItemNumCode,
                      locationCode,
                      price,
                      contractNum,
                      proposedPONum,
                      quantity: map(quantity, ({ quantity }) => ({
                        quantity,
                        type: "47",
                      })),
                    })
                  ),
                });
              }
            },
          },
        ],
        columns: [
          {
            title: "PO.PO_NUM",
            dataIndex: "reportNum",
            key: "reportNum",
          },
          {
            title: "PO.INVOICEE",
            dataIndex: "invoiceeEAN",
            key: "invoiceeEAN",
          },
          {
            title: "PO.MSG_DATE",
            dataIndex: "messageDate",
            key: "messageDate",
          },
          {
            title: "PO.CURRENCY",
            dataIndex: "currency",
            key: "currency",
          },
          {
            title: "POCHANGE.PO_CHANGE_NUM",
            dataIndex: "poChangeNum",
            key: "poChangeNum",
          },
          {
            title: "POCHANGE.FUNC",
            dataIndex: "function",
            key: "function",
          },
        ],
        expandable: {
          rowKey: "lineNum",
          transform: {
            itemEAN: {
              collection: "inventory",
              labelKey: "haItemCode",
              valueKey: "gtin",
              to: "haItemNum",
            },
          },
          columns: [
            {
              title: "PO.GTIN",
              dataIndex: "itemEAN",
              key: "itemEAN",
            },
            {
              title: "PO.HAITEM_NUM",
              dataIndex: "haItemNum",
              key: "haItemNum",
            },
            {
              title: "PO.CONTRACT_NUM",
              dataIndex: "contractNum",
              key: "contractNum",
            },
            {
              title: "PO.DELIVERY_DATE",
              dataIndex: "deliverydate",
              key: "deliverydate",
            },
            {
              title: "PO.LOC_CODE",
              dataIndex: "locationCode",
              key: "locationCode",
            },
            {
              title: "PO.PRICE",
              dataIndex: "price",
              key: "price",
            },
            {
              title: "PO.QUANTITY",
              dataIndex: "quantity",
              key: "quantity",
              render: (data: any) => {
                return (
                  data &&
                  data.map(({ quantity }: any) => <span>{quantity}</span>)
                );
              },
            },
          ],
          id: "line",
          expandable: {
            rowKey: "notes",
            title: "PO.QUANTITY_NOTE",
            columns: [
              {
                title: "PO.TYPE",
                dataIndex: "type",
                key: "type",
              },
              {
                title: "PO.QUANTITY_NOTE",
                dataIndex: "notes",
                key: "notes",
                render: (data: any, record: any, index: number) => {
                  return (
                    data &&
                    data.map(({ text, type }: any) => {
                      return (
                        <Card
                          key={`q_notes_${index}`}
                          size="small"
                          title={type}
                          style={{ width: "100%" }}
                        >
                          {text && text.map((txt: string) => <p>{txt}</p>)}
                        </Card>
                      );
                    })
                  );
                },
              },
            ],
            id: "quantity",
          },
        },
      },
    ],
  },
};

export { template };
