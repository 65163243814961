const template = {
  id: 'inventorystoreView',
  collection: 'inventorystore',
  view: 'view',
  template: {
    type: 'dialog',
    title: 'INVENTORYSTORE.VIEW',
    closable: true,
    hideFooter: true,
    items: [{
      type: 'desc',
      id: 'newView',
      bordered: true,
      layout: 'vertical',
      items: [{
        type: 'label',
        id: 'store',
        label: 'STORE.NAME',
        optionsMap: {
          collection: 'store',
          valueKey: '_id',
          labelKey: 'name',
        }
      }, {
        type: 'label',
        id: 'inventory',
        label: 'INVENTORY.NAME',
        optionsMap: {
          collection: 'inventory',
          valueKey: '_id',
          labelKey: 'name',
        }
      }, {
        type: 'label',
        id: 'tMinQT',
        label: 'INVENTORYSTORE.TMINQT',
      }, {
        type: 'label',
        id: 'minBX',
        label: 'INVENTORYSTORE.MINBX',
      }, {
        type: 'label',
        id: 'tMaxQT',
        label: 'INVENTORYSTORE.TMAXQT',
      }, {
        type: 'label',
        id: 'maxBX',
        label: 'INVENTORYSTORE.MAXBX',
      }],
    }]
  }
}

export {
  template
}