import { Descriptions } from "antd";
import { find, get, isArray, isPlainObject, map } from "lodash";
import { BaseComponent } from "./baseComponent";

export class Desc extends BaseComponent {
  constructor() {
    super('desc');
  }

  public renderComponent(item: any, { t, model, optionsMap }: any): any {
    const { items, title, ...otherProps } = item;
    return <Descriptions title={t(title)} {...otherProps}>
    {map(items, ({ label, id, optionsMap: itemOptionsMap = {} }) => {
      const { collection, valueKey = 'value', labelKey = 'label', optionsKey } = itemOptionsMap;
      let val = get(model, id);
      if (!isArray(val) && !isPlainObject(val) && ((collection && get(optionsMap, [collection, 'options'])) || optionsKey)) {
        val = get(find(get(optionsMap, [collection || optionsKey, 'options']), (opt) => (opt[valueKey] === val || opt[valueKey] === `${val}`)), labelKey);
      }
      return <Descriptions.Item label={t(label)}>{val}</Descriptions.Item>
    })}
  </Descriptions>
  }
};
