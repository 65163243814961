import { Card } from "antd";
import { set, sumBy } from "lodash";
import moment from "moment";
import { Proposedpo } from "../collections/proposedpo";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const template = {
  id: "proposedpoTable",
  collection: "proposedpo",
  view: "table",
  template: {
    type: "space",
    items: [
      {
        type: "space",
        cssClass: "space-padding",
        direction: "horizontal",
        items: [
          {
            id: "newProposedPo",
            type: "button",
            subType: "primary",
            shape: "round",
            icon: {
              type: "icon",
              subType: "plus",
              theme: "outlined",
            },
            label: "GENERAL.CREATE",
            onClick: ({ openCollection }: any) => {
              if (openCollection) {
                openCollection("proposedpo", "edit", "new", {
                  messageDate: moment().format("YYYY-MM-DD"),
                });
              }
            },
          },
        ],
      },
      {
        id: "proposedpo",
        type: "table",
        rowKey: "reportNum",
        searchable: true,
        canDelete: ({ data }: any) => data.status !== 'UPLOADED',
        canEdit: ({ data }: any) => data.status !== 'UPLOADED',
        onSearch: (value: string, pipe: any) => {
          const { setFilters, setKeyword, refreshTable, handlePageChange } = pipe;
          setKeyword(value);
          setFilters([
            "reportNum",
            "storeEAN",
            "line.haItemNum",
            "line.itemEAN",
          ]);
          handlePageChange({ page: 1 });
          refreshTable();
        },
        extraActions: [
          {
            id: "upload",
            icon: {
              type: "icon",
              subType: "cloud-upload",
              theme: "outlined",
            },
            hidden: ({ data }: any) => data.status === 'UPLOADED',
            showLoading: true,
            onClick: async (event: any, pipe: any) => {
              const { setLoadings, refreshTable } = pipe;
              const { originalRecord, index } = event;
              try {
                const { reportNum } = originalRecord || {};
                const ppoSvc = new Proposedpo();
                setLoadings((prevLoadings: any) => {
                  const newLoadings = { ...prevLoadings };
                  set(newLoadings, ["upload", index], true);
                  return newLoadings;
                });
                const { status, data } = await ppoSvc.upload(reportNum);
                if (status) {
                  showToast(
                    {
                      type: "success",
                      title: "GENERAL.SUCCESS",
                      description: `${status}`,
                    },
                    pipe.t
                  );
                  console.log(`${JSON.stringify(data)}`);
                }
              } catch (error) {
                showToast(
                  {
                    type: "error",
                    title: "ERROR.TITLE",
                    description: (error as ApiError).getMessage(),
                  },
                  pipe.t
                );
              } finally {
                setLoadings((prevLoadings: any) => {
                  const newLoadings = { ...prevLoadings };
                  set(newLoadings, ["upload", index], false);
                  return newLoadings;
                });
                refreshTable();
              }
            },
          },
        ],
        columns: [
          {
            title: "INVRPT.REPORT_NUM",
            dataIndex: "reportNum",
            key: "reportNum",
          },
          {
            title: "INVRPT.STORE_LOC_NUM",
            dataIndex: "storeEAN",
            key: "storeEAN",
          },
          {
            title: "PO.MSG_DATE",
            dataIndex: "messageDate",
            key: "messageDate",
          },
          {
            title: "ASN.DESPATCH_DATE",
            dataIndex: "despatchDate",
            key: "despatchDate",
          },
          {
            title: "POCHANGE.FUNC",
            dataIndex: "function",
            key: "function",
          },
          {
            title: "COMMON.STATUS",
            dataIndex: "status",
            key: "status",
          },
        ],
        transform: {
          function: {
            optionsKey: "FUNC_CODE_MAPPING",
          },
        },
        expandable: {
          rowKey: "lineNum",
          columns: [
            {
              title: "PO.GTIN",
              dataIndex: "itemEAN",
              key: "itemEAN",
            },
            {
              title: "PO.HAITEM_NUM",
              dataIndex: "haItemNum",
              key: "haItemNum",
            },
            {
              title: "PO.QUANTITY",
              dataIndex: "quantity",
              key: "quantity",
              render: (data: any) => {
                return data && <div>{sumBy(data, "quantity")}</div>;
              },
            },
          ],
          id: "line",
          expandable: {
            rowKey: "notes",
            title: "PO.QUANTITY_NOTE",
            transform: {
              type: {
                optionsKey: "QUANTITY_CODE_MAPPING",
              },
            },
            columns: [
              {
                title: "PO.TYPE",
                dataIndex: "type",
                key: "type",
              },
              {
                title: "PO.QUANTITY_NOTE",
                dataIndex: "notes",
                key: "notes",
                render: (data: any, record: any, index: number) => {
                  return (
                    data &&
                    data.map(({ text, type }: any) => {
                      return (
                        <Card
                          key={`q_notes_${index}`}
                          size="small"
                          title={type}
                          style={{ width: "100%" }}
                        >
                          {text && text.map((txt: string) => <p>{txt}</p>)}
                        </Card>
                      );
                    })
                  );
                },
              },
            ],
            id: "quantity",
          },
        },
      },
    ],
  },
};

export { template };
