import { Row } from "antd";
import { get, map } from "lodash";
import { BaseComponent } from "./baseComponent";

export class RowComponent extends BaseComponent {
  constructor() {
    super('row');
  }
  public renderComponent(item: any, pipe: any): any {
    const {
      id, type, ...otherProps
    } = item;
    return <Row {...otherProps}>
    {map(get(item, 'items', []), item => pipe.renderComponent(item, pipe))}
  </Row>
  }
};
