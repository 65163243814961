import './App.less';
import { Router } from './routes/routes';
import { LoginPage } from './pages/login/login';
import { HomePage } from './pages/home/home';
function App() {
  return (
    <div className="App">
      <Router routes={[{
        id: 'login',
        path: '',
        open: true,
        element: <LoginPage/>
      }, {
        id: 'login',
        path: 'login',
        open: true,
        element: <LoginPage/>
      }, {
        id: 'home',
        path: 'home',
        open: false,
        element: <HomePage/>,
      }]}></Router>
    </div>
  );
}

export default App;
