import { get, isFunction, merge } from "lodash";
import { Poresponse } from "../collections/poresponse";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const onFinish = async (values: any, pipe: any) => {
  const { t, handleDialogConfirm, currentCollection, extraValue, auth } = pipe;
  const updateModel = merge({ ...values, messageDate: get(values, 'messageDate').format('YYYY-MM-DD') }, extraValue);
  console.log('Received values of form: ', updateModel);
  const { mode } = currentCollection;
  const poresponseSvc = new Poresponse(auth);
  try {
    if (mode === 'new') {
      await poresponseSvc.create(updateModel);
    } else {
      await poresponseSvc.update(mode, updateModel);
    }
  } catch (error: any) {
    showToast({
      type: 'error',
      title: 'ERROR.TITLE',
      description: (error as ApiError).getMessage()
    }, t)
  } finally {
    if (isFunction(handleDialogConfirm)) {
      handleDialogConfirm(pipe);
    }
  }
};

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

const template = {
  id: 'poresponseEdit',
  collection: 'poresponse',
  view: 'edit',
  template: {
    type: 'dialog',
    title: 'PORESPONSE.NEW',
    closable: false,
    onOK: ({ model }: any) => {
      console.log('onOK', model);
    },
    onCancel: ({ model }: any) => {
      console.log('onCancel', model);
    },
    items: [{
      type: 'form',
      id: 'newForm',
      items: [{
        type: 'input',
        id: 'reportNum',
        label: 'INVRPT.REPORT_NUM',
        placeholder: 'INVRPT.REPORT_NUM',
        disabled: true,
      }, {
        type: 'input',
        id: 'poNum',
        label: 'PO.PO_NUM',
        placeholder: 'PO.PO_NUM',
        disabled: true,
      }, {
        type: 'input',
        id: 'invoiceeEAN',
        label: 'PO.INVOICEE',
        placeholder: 'PO.INVOICEE',
        disabled: true,
      }, {
        type: 'date',
        id: 'messageDate',
        label: 'PO.MSG_DATE',
        placeholder: 'PO.MSG_DATE',
        format: 'YYYY-MM-DD',
        mandatory: true,
      }, {
        type: 'input',
        id: 'currency',
        label: 'PO.CURRENCY',
        placeholder: 'PO.CURRENCY',
        mandatory: true,
        initialValue: 'HKD'
      }, {
        type: 'input',
        id: 'poChangeNum',
        label: 'POCHANGE.PO_CHANGE_NUM',
        placeholder: 'POCHANGE.PO_CHANGE_NUM',
        disabled: true,
      }, {
        type: 'picker',
        id: 'function',
        label: 'POCHANGE.FUNC',
        placeholder: 'POCHANGE.FUNC',
        mandatory: true,
        initialValue: '29',
        optionsMap: {
          optionsKey: 'FUNC_CODE_MAPPING'
        }
      }],
      onFinish,
      onFinishFailed,
    }]
  }
}

export {
  template
}