import { Col } from "antd";
import { get, map } from "lodash";
import { BaseComponent } from "./baseComponent";

export class ColComponent extends BaseComponent {
  constructor() {
    super('col');
  }
  public renderComponent(item: any, pipe: any): any {
    const {
      id, type, ...otherProps
    } = item;
    return <Col {...otherProps}>
    {map(get(item, 'items', []), item => pipe.renderComponent(item, pipe))}
  </Col>
  }
};
