import { collections } from "../../collections/collections";
import { Collection } from "../../services/collection";
import { Templates } from "../../services/templates";
import {
  capitalize,
  each,
  filter,
  get,
  isArray,
  isFunction,
  map,
  set,
  uniq,
} from "lodash";
import "./view.css";
import { useEffect, useState } from "react";
import { showToast } from "../../services/toast";
import { ApiError } from "../../domains/error";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Promise } from "bluebird";
import { Spin } from 'antd';
const DEFAULT_PAGE = 1;
const DEFAULT_PAGESIZE = 10;
const CollectionView = (props: any) => {
  const [model, setModel] = useState<any>({
    page: DEFAULT_PAGE,
    pageSize: DEFAULT_PAGESIZE,
    total: 0,
  });
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [collection, setCollection] = useState<Collection | undefined>(
    undefined
  );
  const [loadings, setLoadings] = useState({});
  const [keyword, setKeyword] = useState<string>();
  const [filters, setFilters] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { name, view, mode, model: initModel, pipe } = props;

  const getCollection = (name: string): Collection =>
    new (get(collections, capitalize(name)))() || new Collection(name);
  const getTemplates = () => {
    if (!props || !name || !collection) {
      return {};
    }
    return collection.getTemplates();
  };

  const getOptionsMap = (template: any) => {
    if (!template) {
      return [];
    }
    let collectionsList: string[] = [];
    each(
      isArray(template) ? template : [template],
      ({ items, optionsMap, transform, expandable }) => {
        if (items) {
          const collection: string[] = getOptionsMap(items);
          collectionsList.push(...collection);
        } else if (optionsMap && optionsMap.collection) {
          collectionsList.push(optionsMap.collection);
        } else if (transform) {
          const collection: string[] = filter(
            map(transform, ({ collection }, k) => collection),
            (o: any) => !!o
          );
          collectionsList.push(...collection);
        }
        if (expandable) {
          const collection: string[] = getOptionsMap([expandable]);
          collectionsList.push(...collection);
        }
      }
    );
    return uniq(collectionsList);
  };

  const getTemplate = (view: string) => {
    if (!props || !name || !collection) {
      return {};
    }
    const template = collection.getTemplate(view);
    return template;
  };

  const handleDialogConfirm = (pipe: any) => {
    setIsDialogShown(false);
    if (isFunction(pipe.openCollection)) {
      pipe.openCollection(name, "table");
    }
  };

  const handleDialogCancel = (pipe: any) => {
    setIsDialogShown(false);
    if (isFunction(pipe.openCollection)) {
      pipe.openCollection(name, "table");
    }
  };

  const refreshTable = () => {
    setCollection(getCollection(name));
  };

  const actions: any = {
    handleViewAction: (event: any, pipe: any) => {
      const { text } = event;
      if (isFunction(pipe.openCollection)) {
        pipe.openCollection(name, "view", text);
      }
    },
    handleEditAction: (event: any, pipe: any) => {
      const { text = "dummy" } = event;
      if (isFunction(pipe.openCollection)) {
        pipe.openCollection(name, "edit", text);
      }
    },
    handleDeleteAction: (event: any, { t }: any) => {
      Modal.confirm({
        title: t("GENERAL.DELETE"),
        icon: <ExclamationCircleOutlined />,
        content: t("GENERAL.DELETE_CONFIRM_MSG"),
        okText: t("GENERAL.DELETE"),
        okType: "danger",
        cancelText: t("GENERAL.CANCEL"),
        onOk: async () => {
          const { text } = event;
          console.log("Delete", text);
          await collection?.remove(text);
          refreshTable();
        },
        onCancel: () => {},
      });
    },
  };

  const handleTableAction = (type: any, event: any, pipe: any) => {
    if (!type || !actions[`handle${capitalize(type)}Action`]) {
      return;
    }
    if (isFunction(actions[`handle${capitalize(type)}Action`])) {
      actions[`handle${capitalize(type)}Action`](event, pipe);
    }
  };

  const handlePageChange = ({ page, pageSize = 10 }: any) => {
    if (!page) {
      return;
    }
    setModel({
      ...model,
      page,
      pageSize
    });
  };

  useEffect(() => {
    if (
      !collection ||
      (collection && collection?.getCollectionName() !== name)
    ) {
      setCollection(getCollection(name));
    }
    const collectionsList = getOptionsMap(get(getTemplate(view), "template"));
    const getOptions = async () => {
      if (collectionsList && collectionsList.length) {
        await Promise.mapSeries(collectionsList, async (collectionName) => {
          set(
            pipe,
            `optionsMap.${collectionName}.options`,
            (await getCollection(collectionName).list()) || []
          );
        });
        pipe.optionsMap = {
          ...pipe.optionsMap,
        };
      }
    };
    const getModel = async (
      params: any = {
        page: DEFAULT_PAGE,
        pageSize: DEFAULT_PAGESIZE,
      }
    ) => {
      if (!collection) {
        setModel({
          ...params,
          total: 0,
        });
        return;
      }
      try {
        setIsLoading(true);
        const { records, total } = await (keyword
          ? collection.search({ ...params, keyword, filters })
          : collection.list(params));
        await getOptions();
        setModel({
          [name]: records,
          page: params.page,
          pageSize: params.pageSize,
          total,
        });
      } catch (error) {
        showToast(
          {
            type: "error",
            title: "ERROR.TITLE",
            description: (error as ApiError)?.getMessage(),
          },
          pipe.t
        );
      } finally {
        setIsLoading(false);
      }
    };
    const getModelById = async (id: string) => {
      if (!collection || !id || id === "new") {
        await getOptions();
        setModel(initModel ? initModel : {});
        return;
      }
      try {
        setIsLoading(true);
        const result = await collection.find(id);
        await getOptions();
        setModel(result);
      } catch (error) {
        showToast(
          {
            type: "error",
            title: "ERROR.TITLE",
            description: (error as ApiError).getMessage(),
          },
          pipe.t
        );
      } finally {
        setIsLoading(false);
      }
    };
    const getDashboard = async () => {
      if (!collection) {
        await getOptions();
        setModel(initModel ? initModel : {});
        return;
      }
      try {
        setIsLoading(true);
        const result = await collection.list();
        await getOptions();
        setModel(result);
      } catch (error) {
        showToast(
          {
            type: "error",
            title: "ERROR.TITLE",
            description: (error as ApiError).getMessage(),
          },
          pipe.t
        );
      } finally {
        setIsLoading(false);
      }
    };
    if (["edit", "view"].includes(view)) {
      getModelById(mode).then(() => setIsDialogShown(true));
    } else if (["table"].includes(view)) {
      getModel({
        page: model.page || DEFAULT_PAGE,
        pageSize: model.pageSize || DEFAULT_PAGESIZE,
      });
    } else if (["dashboard"].includes(view)) {
      getDashboard();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, view, mode, collection, model.page, model.pageSize]);
  return (
    <div className={`${view}-view`}>
      <Spin tip="Loading..." spinning={isLoading}>
        <Templates
          template={getTemplate(view)}
          pipe={{
            ...pipe,
            model,
            extraValue: {},
            isDialogShown,
            handleDialogCancel,
            handleDialogConfirm,
            handleTableAction,
            handlePageChange,
            setLoadings,
            loadings,
            refreshTable,
            setKeyword,
            setFilters,
          }}
        ></Templates>
      </Spin>
    </div>
  );
};

export { CollectionView };
