import { get } from "lodash";
import { BaseComponent } from "./baseComponent";
import { Button, Upload } from 'antd';

export class UploadComponent extends BaseComponent {
  constructor() {
    super('upload');
  }
  public renderComponent(item: any, pipe: any): any {
    const {
      id, type, subType, htmlType, cssClass, label, onClick, icon, showLoading = false, beforeUpload, customRequest, shape,
      ...otherProps
    } = item;
    
  return <Upload key={id} beforeUpload={(file, fileList) => beforeUpload && beforeUpload(file, fileList, pipe)}
      customRequest={(options: any) => customRequest && customRequest(options, pipe)} {...otherProps}>
    <Button type={subType} shape={shape} loading={showLoading && get(pipe, ['loadings', id])} className={ cssClass } htmlType={ htmlType } icon={pipe.renderComponent(icon, pipe)}>{pipe.t(label)}</Button>
  </Upload>
  }
};
