import { Card } from "antd";
const template = {
  id: "pochangeView",
  collection: "pochange",
  view: "view",
  template: {
    type: "dialog",
    title: "PO.VIEW",
    closable: true,
    hideFooter: true,
    items: [
      {
        type: "desc",
        id: "newView",
        bordered: true,
        layout: "vertical",
        items: [
          {
            type: "label",
            id: "reportNum",
            label: "PO.PO_NUM",
          },
          {
            type: "label",
            id: "invoiceeEAN",
            label: "PO.INVOICEE",
          },
          {
            type: "label",
            id: "messageDate",
            label: "PO.MSG_DATE",
          },
          {
            type: "label",
            id: "currency",
            label: "PO.CURRENCY",
          },
          {
            type: "label",
            label: "POCHANGE.PO_CHANGE_NUM",
            id: "poChangeNum",
          },
          {
            label: "POCHANGE.FUNC",
            id: "function",
            type: "label",
          },
        ],
      },
      {
        type: "table",
        rowKey: "lineNum",
        canDelete: false,
        canEdit: false,
        canView: false,
        columns: [
          {
            title: "PO.LINENUM",
            dataIndex: "lineNum",
            key: "lineNum",
          },
          {
            title: "PO.GTIN",
            dataIndex: "itemEAN",
            key: "itemEAN",
          },
          {
            title: "PO.HAITEM_NUM",
            dataIndex: "haItemNum",
            key: "haItemNum",
          },
          {
            title: "PO.CONTRACT_NUM",
            dataIndex: "contractNum",
            key: "contractNum",
          },
          {
            title: "PO.DELIVERY_DATE",
            dataIndex: "deliverydate",
            key: "deliverydate",
          },
          {
            title: "PO.LOC_CODE",
            dataIndex: "locationCode",
            key: "locationCode",
          },
          {
            title: "PO.PRICE",
            dataIndex: "price",
            key: "price",
          },
          {
            title: "PO.PPO_NUM",
            dataIndex: "proposedPONum",
            key: "proposedPONum",
          },
          {
            title: "PO.MANUFACTURERS",
            dataIndex: "manufacturers",
            key: "manufacturers",
            render: (data: any) => {
              return (
                data &&
                data.map(({ country, name }: any) => (
                  <span>
                    {country}&nbsp;{name ? `:${name}` : ""}
                    <br />
                  </span>
                ))
              );
            },
          },
        ],
        id: "line",
        expandable: {
          rowKey: "notes",
          title: "PO.QUANTITY_NOTE",
          transform: {
            type: {
              optionsKey: "QUANTITY_CODE_MAPPING",
            },
          },
          columns: [
            {
              title: "PO.QUANTITY",
              dataIndex: "quantity",
              key: "quantity",
            },
            {
              title: "PO.TYPE",
              dataIndex: "type",
              key: "type",
            },
            {
              title: "PO.QUANTITY_NOTE",
              dataIndex: "notes",
              key: "notes",
              render: (data: any, record: any, index: number) => {
                return (
                  data &&
                  data.map(({ text, type }: any) => {
                    return (
                      <Card
                        key={`q_notes_${index}`}
                        size="small"
                        title={type}
                        style={{ width: "100%" }}
                      >
                        {text && text.map((txt: string) => <p>{txt}</p>)}
                      </Card>
                    );
                  })
                );
              },
            },
          ],
          id: "quantity",
        },
      },
    ],
  },
};

export { template };
