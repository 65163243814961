import { sumBy } from "lodash";
import { Inventory } from "../collections/inventory";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const template = {
  id: "inventoryTable",
  collection: "inventory",
  view: "table",
  template: {
    type: "space",
    items: [
      {
        type: "space",
        cssClass: "space-padding",
        direction: "horizontal",
        items: [
          {
            id: "newInventory",
            type: "button",
            subType: "primary",
            shape: "round",
            icon: {
              type: "icon",
              subType: "plus",
              theme: "outlined",
            },
            label: "GENERAL.CREATE",
            onClick: ({ openCollection }: any) => {
              if (openCollection) {
                openCollection("inventory", "edit", "new");
              }
            },
          },
          {
            id: "importData",
            type: "upload",
            subType: "primary",
            shape: "round",
            icon: {
              type: "icon",
              subType: "cloud-upload",
              theme: "outlined",
            },
            label: "GENERAL.IMPORT_EXCEL",
            customRequest: async (options: any, pipe: any) => {
              const { setLoadings, refreshTable } = pipe;
              try {
                setLoadings((prevLoadings: any) => ({
                  ...prevLoadings,
                  importData: true,
                }));
                const file = options.file as File;
                const inventorySvc = new Inventory();
                const json = await inventorySvc.convertXlsxToJson(file);
                const result = await inventorySvc.import(json);
                showToast(
                  {
                    type: "success",
                    title: "GENERAL.SUCCESS",
                    description: `Import success.`,
                  },
                  pipe.t
                );
                options.onSuccess(result);
              } catch (error) {
                showToast(
                  {
                    type: "error",
                    title: "ERROR.TITLE",
                    description: (error as ApiError).getMessage(),
                  },
                  pipe.t
                );
                options.onError(error);
              } finally {
                setLoadings((prevLoadings: any) => ({
                  ...prevLoadings,
                  importData: false,
                }));
                refreshTable();
              }
            },
          },
          {
            id: "importGTINs",
            type: "upload",
            subType: "primary",
            shape: "round",
            icon: {
              type: "icon",
              subType: "cloud-upload",
              theme: "outlined",
            },
            label: "GENERAL.IMPORT_GTIN_BY_EXCEL",
            customRequest: async (options: any, pipe: any) => {
              const { setLoadings, refreshTable } = pipe;
              try {
                setLoadings((prevLoadings: any) => ({
                  ...prevLoadings,
                  importGTINs: true,
                }));
                const file = options.file as File;
                const inventorySvc = new Inventory();
                const json = await inventorySvc.convertXlsxToJson(file);
                const result = await inventorySvc.importGTINs(json);
                showToast(
                  {
                    type: "success",
                    title: "GENERAL.SUCCESS",
                    description: `Import success.`,
                  },
                  pipe.t
                );
                options.onSuccess(result);
              } catch (error) {
                showToast(
                  {
                    type: "error",
                    title: "ERROR.TITLE",
                    description: (error as ApiError).getMessage(),
                  },
                  pipe.t
                );
                options.onError(error);
              } finally {
                setLoadings((prevLoadings: any) => ({
                  ...prevLoadings,
                  importGTINs: false,
                }));
                refreshTable();
              }
            },
          },
          {
            id: "importBatchs",
            type: "upload",
            subType: "primary",
            shape: "round",
            icon: {
              type: "icon",
              subType: "cloud-upload",
              theme: "outlined",
            },
            label: "GENERAL.IMPORT_BATCHS_BY_EXCEL",
            customRequest: async (options: any, pipe: any) => {
              const { setLoadings, refreshTable } = pipe;
              try {
                setLoadings((prevLoadings: any) => ({
                  ...prevLoadings,
                  importBatchs: true,
                }));
                const file = options.file as File;
                const inventorySvc = new Inventory();
                const json = await inventorySvc.convertXlsxToJson(file);
                const result = await inventorySvc.importBatchs(json);
                showToast(
                  {
                    type: "success",
                    title: "GENERAL.SUCCESS",
                    description: `Import success.`,
                  },
                  pipe.t
                );
                options.onSuccess(result);
              } catch (error) {
                showToast(
                  {
                    type: "error",
                    title: "ERROR.TITLE",
                    description: (error as ApiError).getMessage(),
                  },
                  pipe.t
                );
                options.onError(error);
              } finally {
                setLoadings((prevLoadings: any) => ({
                  ...prevLoadings,
                  importBatchs: false,
                }));
                refreshTable();
              }
            },
          },
        ],
      },
      {
        id: "inventory",
        type: "table",
        rowKey: "_id",
        canDelete: false,
        searchable: true,
        onSearch: (value: string, pipe: any) => {
          const { setFilters, setKeyword, refreshTable, handlePageChange } = pipe;
          setKeyword(value);
          setFilters(["cfn", "name", "haItemCode", "gtin", "lots.lotNo"]);
          handlePageChange({ page: 1 });
          refreshTable();
        },
        columns: [
          {
            title: "INVENTORY.CFN",
            dataIndex: "cfn",
            key: "cfn",
          },
          {
            title: "INVENTORY.NAME",
            dataIndex: "name",
            key: "name",
          },
          {
            title: "INVENTORY.DESCRIPTION",
            dataIndex: "description",
            key: "description",
          },
          {
            title: "INVRPT.HAITEM_NUM",
            dataIndex: "haItemCode",
            key: "haItemCode",
          },
          {
            title: "INVRPT.GTIN",
            dataIndex: "gtin",
            key: "gtin",
          },
          {
            title: "INVENTORY.PACKSIZE",
            dataIndex: "packSize",
            key: "packSize",
          },
          {
            title: "INVENTORY.PRICE",
            dataIndex: "unitPrice",
            key: "unitPrice",
          },
          {
            title: "INVENTORY.QUANTITY",
            dataIndex: "lots",
            key: "quantity",
            render: (data: any) => {
              return data && <div>{sumBy(data, "quantity")}</div>;
            },
          },
          {
            title: "INVENTORY.UOM",
            dataIndex: "uom",
            key: "uom",
          },
          {
            title: "INVENTORY.BRAND",
            dataIndex: "brand",
            key: "brand",
          },
          {
            title: "INVENTORY.MANUFACTURER",
            dataIndex: "manufacturer",
            key: "manufacturer",
          },
          {
            title: "INVENTORY.COUNTRY",
            dataIndex: "country",
            key: "country",
          },
        ],
        dataType: {
          packSize: {
            type: "number",
          },
          unitPrice: {
            type: "currency",
          },
          quantity: {
            type: "number",
          },
        },
        expandable: {
          rowKey: "lotNo",
          columns: [
            {
              title: "INVENTORY.LOTNUM",
              dataIndex: "lotNo",
              key: "lotNo",
            },
            {
              title: "INVENTORY.EXPIRYDATE",
              dataIndex: "expiryDate",
              key: "expiryDate",
            },
            {
              title: "INVENTORY.QUANTITY",
              dataIndex: "quantity",
              key: "quantity",
            },
          ],
          id: "lots",
        },
      },
    ],
  },
};

export { template };
