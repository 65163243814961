import { Collection } from "../services/collection";
import { get, post } from "../services/request";
import { template as table } from '../templates/tp-po';
import { template as view } from '../templates/tp-po-view';

export class Po extends Collection {
  constructor(auth?: any) {
    super('po', {
      table,
      view
    }, auth);
  }
  public fetch = async () => get('po/fetch');
  public import = async (document: string, filename: string) => post('po/import', { document, filename });
}