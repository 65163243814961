import { InputNumber } from "antd";
import { BaseComponent } from "./baseComponent";

export class NumberField extends BaseComponent {
  constructor() {
    super('number');
  }
  public renderComponent(item: any, pipe: any): any {
    const {
      id, type, prefix, suffix, placeholder, subType, cssClass, label,
      mandatory, mandatoryMessage, icon,
      ...otherProps
    } = item;
    return <InputNumber key={id} prefix={pipe.renderComponent(icon, pipe)} addonBefore={prefix && pipe.renderComponent(prefix, pipe)} addonAfter={suffix && pipe.renderComponent(suffix, pipe)} placeholder={ pipe.t(placeholder) } label={ pipe.t(label) } type={ subType } className={ cssClass } {...otherProps}/>
  }
};
