import { Input } from "antd";
import { BaseComponent } from "./baseComponent";

export class TextArea extends BaseComponent {
  constructor() {
    super('textarea');
  }
  public renderComponent(item: any, pipe: any): any {
    const {
      id, type, prefix, suffix, placeholder, subType, cssClass, label,
      mandatory, mandatoryMessage, icon,
      ...otherProps
    } = item;
    return <Input.TextArea key={id} prefix={pipe.renderComponent(icon, pipe)} suffix={pipe.renderComponent(suffix, pipe)} placeholder={ pipe.t(placeholder) } label={ pipe.t(label) } type={ subType } className={ cssClass } {...otherProps}/>
  }
};
