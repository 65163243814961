import { read, utils } from "xlsx";
import { Collection } from "../services/collection";
import { post } from "../services/request";
import { template as table } from '../templates/tp-location';
import { template as view } from '../templates/tp-location-view';

export class Location extends Collection {
  constructor(auth?: any) {
    super('location', {
      table,
      view,
    }, auth);
  }

  public import = async (locations: any) => post('location/import', { locations });
  public convertXlsxToJson = async (file: File) => {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = (e) => {
          const data = e.target?.result;
          const workbook = read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = utils.sheet_to_json(worksheet);
          resolve(json);
      };
      reader.readAsArrayBuffer(file);
    })
  }
}