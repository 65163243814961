import { Collection } from "../services/collection";
import { get } from "../services/request";
import { template as table } from '../templates/tp-inventory-report';

export class Invrpt extends Collection {
  constructor(auth?: any) {
    super('invrpt', {
      table
    }, auth);
  }
  public fetch = async () => get('invrpt/fetch');
}