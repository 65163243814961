const template = {
  id: 'menu',
  template: {
    type: 'menu',
    defaultKey: 'home',
    items: [{
      id: 'home',
      label: 'MENU.HOME',
      icon: {
        subType: 'home',
        type: 'icon',
        theme: 'outlined',
      },
    }, {
      id: 'location',
      label: 'LOCATION.TITLE',
      icon: {
        subType: 'database',
        type: 'icon',
        theme: 'outlined',
      },
    }, {
      id: 'store',
      label: 'STORE.TITLE',
      icon: {
        subType: 'database',
        type: 'icon',
        theme: 'outlined',
      },
    }, {
      id: 'inventory',
      label: 'INVENTORY.TITLE',
      icon: {
        subType: 'database',
        type: 'icon',
        theme: 'outlined',
      },
    }, {
      id: 'inventorystore',
      label: 'INVENTORYSTORE.TITLE',
      icon: {
        subType: 'database',
        type: 'icon',
        theme: 'outlined',
      },
    }, {
      id: 'invrpt',
      label: 'INVRPT.TITLE',
      icon: {
        subType: 'database',
        type: 'icon',
        theme: 'outlined',
      },
    }, {
      id: 'proposedpo',
      label: 'PROPOSEDPO.TITLE',
      icon: {
        subType: 'database',
        type: 'icon',
        theme: 'outlined',
      },
    }, {
      id: 'po',
      label: 'PO.TITLE',
      icon: {
        subType: 'database',
        type: 'icon',
        theme: 'outlined',
      },
    }, {
      id: 'pochange',
      label: 'POCHANGE.TITLE',
      icon: {
        subType: 'database',
        type: 'icon',
        theme: 'outlined',
      },
    }, {
      id: 'poresponse',
      label: 'PORESPONSE.TITLE',
      icon: {
        subType: 'database',
        type: 'icon',
        theme: 'outlined',
      },
    }, {
      id: 'asn',
      label: 'ASN.TITLE',
      icon: {
        subType: 'database',
        type: 'icon',
        theme: 'outlined',
      },
    }, {
      id: 'invoice',
      label: 'INVOICE.TITLE',
      icon: {
        subType: 'database',
        type: 'icon',
        theme: 'outlined',
      },
    }],
    onClick: ({ key }: any, { openCollection }: any) => {
      let target = key;
      let view = 'table';
      if (key === 'home') {
        target = 'dashboard';
        view = 'dashboard'
      }
      if (openCollection) {
        openCollection(target, view);
      }
    }
  }
}
export {
  template
}