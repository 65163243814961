import { set } from "lodash";
import { Asn } from "../collections/asn";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const template = {
  id: "asnTable",
  collection: "asn",
  view: "table",
  template: {
    type: "space",
    items: [
      {
        id: "asn",
        type: "table",
        rowKey: "reportNum",
        searchable: true,
        canDelete: ({ data }: any) => data.status !== "UPLOADED",
        canEdit: ({ data }: any) => data.status !== "UPLOADED",
        onSearch: (value: string, pipe: any) => {
          const { setFilters, setKeyword, handlePageChange, refreshTable } =
            pipe;
          setKeyword(value);
          setFilters([
            "reportNum",
            "poNum",
            "deliveryPartyEAN",
            "despatchNoteNum",
            "line.itemEAN",
            "line.batchNum",
          ]);
          handlePageChange({ page: 1 });
          refreshTable();
        },
        extraActions: [
          {
            id: "upload",
            icon: {
              type: "icon",
              subType: "cloud-upload",
              theme: "outlined",
            },
            hidden: ({ data }: any) => data.status === "UPLOADED",
            showLoading: true,
            onClick: async (event: any, pipe: any) => {
              const { setLoadings, refreshTable } = pipe;
              const { originalRecord, index } = event;
              try {
                const { reportNum } = originalRecord || {};
                const asnSvc = new Asn();
                setLoadings((prevLoadings: any) => {
                  const newLoadings = { ...prevLoadings };
                  set(newLoadings, ["upload", index], true);
                  return newLoadings;
                });
                const { status, data } = await asnSvc.upload(reportNum);
                if (status) {
                  showToast(
                    {
                      type: "success",
                      title: "GENERAL.SUCCESS",
                      description: `${status}`,
                    },
                    pipe.t
                  );
                  console.log(`${JSON.stringify(data)}`);
                }
              } catch (error) {
                showToast(
                  {
                    type: "error",
                    title: "ERROR.TITLE",
                    description: (error as ApiError).getMessage(),
                  },
                  pipe.t
                );
              } finally {
                setLoadings((prevLoadings: any) => {
                  const newLoadings = { ...prevLoadings };
                  set(newLoadings, ["upload", index], false);
                  return newLoadings;
                });
                refreshTable();
              }
            },
          },
        ],
        columns: [
          {
            title: "INVRPT.REPORT_NUM",
            dataIndex: "reportNum",
            key: "reportNum",
          },
          {
            title: "PO.PO_NUM",
            dataIndex: "poNum",
            key: "poNum",
          },
          {
            title: "ASN.DELIVERY_PARTY",
            dataIndex: "deliveryPartyEAN",
            key: "deliveryPartyEAN",
          },
          {
            title: "PO.MSG_DATE",
            dataIndex: "messageDate",
            key: "messageDate",
          },
          {
            title: "ASN.DESPATCH_DATE",
            dataIndex: "despatchDate",
            key: "despatchDate",
          },
          {
            title: "ASN.DESPATCH_NOTE_NUM",
            dataIndex: "despatchNoteNum",
            key: "despatchNoteNum",
          },
          {
            title: "ASN.TOTAL_QUANTITY",
            dataIndex: "totalValOfQuantityAtLineLvl",
            key: "totalValOfQuantityAtLineLvl",
          },
          {
            title: "COMMON.STATUS",
            dataIndex: "status",
            key: "status",
          },
        ],
        dataType: {
          refNum: {
            type: "string",
          },
          reportNum: {
            type: "string",
          },
          messageDate: {
            type: "string",
          },
          currency: {
            type: "string",
          },
        },
        expandable: {
          rowKey: "lineNum",
          transform: {
            itemEAN: {
              collection: "inventory",
              labelKey: "cfn",
              valueKey: "gtin",
              to: "cfn",
            },
          },
          columns: [
            {
              title: "PO Line #",
              dataIndex: "poLineNum",
              key: "poLineNum",
            },
            {
              title: "PO.GTIN",
              dataIndex: "itemEAN",
              key: "itemEAN",
            }, {
              title: 'CFN',
              dataIndex: 'cfn',
              key: 'cfn',
            }, 
            {
              title: "ASN.BATCH_NUM",
              dataIndex: "batchNum",
              key: "batchNum",
            },
            {
              title: "ASN.REF_DATE",
              dataIndex: "refDate",
              key: "refDate",
            },
            {
              title: "PO.MANUFACTURERS",
              dataIndex: "manufacturers",
              key: "manufacturers",
              render: (data: any) => {
                return (
                  data &&
                  data.map(({ country, name }: any) => (
                    <span>
                      {country}&nbsp;{name ? `:${name}` : ""}
                      <br />
                    </span>
                  ))
                );
              },
            },
            {
              title: "PO.QUANTITY",
              dataIndex: "quantity",
              key: "quantity",
              render: (data: any) => {
                return (
                  data &&
                  data.map(({ quantity }: any) => <span>{quantity}</span>)
                );
              },
            },
          ],
          id: "line",
        },
      },
    ],
  },
};

export { template };
