import { Col, Row, Avatar, Drawer, Button, Modal, Spin } from "antd";
import { get, toUpper } from "lodash";
import "./home.css";
import { useNavigate } from "react-router";
import { useAuth } from "../../routes/routes";
import { useState, useRef } from "react";
import { MenuOutlined, LogoutOutlined } from "@ant-design/icons";
import config from "../../config.json";
import { Translation } from "react-i18next";
import { CollectionPage, Templates } from "../../services/templates";
import { template } from "../../templates/tp-menu";
import { PrintPage } from "../prints/prints";
import { Location } from "../../collections/location";
import { Inventory } from "../../collections/inventory";
import { useReactToPrint } from "react-to-print";

const HomePage = (props: any) => {
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isShowPrintPage, setIsShowPrintPage] = useState(false);
  const [collection, setCollection] = useState("dashboard");
  const [view, setView] = useState("dashboard");
  const [mode, setMode] = useState("new");
  const [model, setModel] = useState(undefined);
  const [printModel, setPrintModel] = useState({});
  const [locations, setLocations] = useState([]);
  const [inventory, setInventory] = useState([]);
  const auth = useAuth();
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const toggleLeftDrawer = () => {
    setVisible(!visible);
  };
  const onClose = () => {
    setVisible(false);
  };
  const openCollection = (
    name: string,
    view?: string,
    mode?: string,
    model?: any
  ) => {
    setCollection(name);
    if (view) {
      setView(view);
    }
    if (mode) {
      setMode(mode);
    }
    if (model) {
      setModel(model);
    }
    onClose();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const showPrintPage = async (pipe: any) => {
    setLoading(true);
    let loc = locations;
    let inv = inventory;
    if (!locations || !locations.length) {
      const locationSvc = new Location();
      loc = await locationSvc.list();
      setLocations(loc);
    }
    if (!inventory || !inventory.length) {
      const inventorySvc = new Inventory();
      inv = await inventorySvc.list();
      setInventory(inv);
    }
    setPrintModel({
      ...pipe,
      optionsMap: {
        ...pipe.optionsMap,
        locations: loc,
        inventory: inv,
      },
    });
    setLoading(false);
    setIsShowPrintPage(true);
  };
  const dismissPrintPage = () => {
    setIsShowPrintPage(false);
  };
  return (
    <Translation>
      {(t) => (
        <Spin
          style={{
            position: "absolute",
            height: "100vh",
            width: "100vw",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
          tip="Loading..."
          spinning={loading}
        >
          <div className="home-page">
            <Drawer
              title={config.projectDisplayName}
              placement="left"
              closable={false}
              onClose={onClose}
              visible={visible}
              key="left"
              className="drawer-menu"
            >
              <div className="top-menu-panel">
                <Templates
                  template={template}
                  pipe={{
                    auth,
                    navigate,
                    t,
                    openCollection,
                    collection,
                    view,
                    mode,
                    model,
                  }}
                ></Templates>
              </div>
              <div className="bottom-menu-panel">
                <Button
                  icon={<LogoutOutlined />}
                  type="text"
                  onClick={() => auth.signout()}
                >
                  {t("MENU.LOGOUT")}
                </Button>
              </div>
            </Drawer>
            <Row justify="space-between" align="middle">
              <Col className="left-panel" span={1}>
                <Button type="text" onClick={toggleLeftDrawer}>
                  <MenuOutlined />
                </Button>
              </Col>
              <Col className="right-panel" span={23}>
                {`${config.projectDisplayName} ${
                  collection ? ` > ${t(`${toUpper(collection)}.TITLE`)}` : ""
                }`}
                <Avatar size="large">
                  {get(get(auth, "user.name", "S").split(" "), "0.0") +
                    get(get(auth, "user.name", "S").split(" "), "1.0", "")}
                </Avatar>
              </Col>
            </Row>
            <Row>
              <Col className="main-panel">
                {collection && (
                  <CollectionPage
                    name={collection}
                    view={view}
                    mode={mode}
                    model={model}
                    pipe={{
                      auth,
                      optionsMap: get(auth, "mappings", {}),
                      navigate,
                      t,
                      openCollection,
                      showPrintPage,
                    }}
                  ></CollectionPage>
                )}
              </Col>
            </Row>

            <Modal
              width={"100%"}
              visible={isShowPrintPage}
              mask={false}
              centered
              onOk={() => handlePrint()}
              onCancel={() => dismissPrintPage()}
              style={{
                padding: 0,
              }}
            >
              <PrintPage pipe={printModel} ref={componentRef} />
            </Modal>
          </div>
        </Spin>
      )}
    </Translation>
  );
};

export { HomePage };
