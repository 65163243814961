import { Collection } from "../services/collection";
import { template as table } from '../templates/tp-store';
import { template as edit } from '../templates/tp-store-edit';
import { template as view } from '../templates/tp-store-view';

export class Store extends Collection {
  constructor(auth?: any) {
    super('store', {
      table,
      edit,
      view
    }, auth);
  }
}