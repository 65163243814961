import { Cascader } from "antd";
import { BaseComponent } from "./baseComponent";

export class LevelPicker extends BaseComponent {
  constructor() {
    super('levelPicker');
  }
  public renderComponent(item: any, pipe: any): any {
    const {
      id, type, placeholder, cssClass, label,
      mandatory, mandatoryMessage, options, ...otherProps
    } = item;
    return <Cascader key={id} placeholder={ pipe.t(placeholder) } options={options} {...otherProps} ></Cascader>
  }
};
