import React from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { get, map } from 'lodash';

const Router = ({ routes }: any) => {
  return (
    <AuthProvider>
      <Routes>
        {map(routes, route => InnerRoute(route))}
      </Routes>
    </AuthProvider>
  )
}

const InnerRoute = ({ id, path, element, items, open }: any) => 
  <Route key={ id } path={ path } element={ open ? element : <RequireAuth>{ element }</RequireAuth> }>
    {map(items, item => InnerRoute(item))}
  </Route>

interface AuthContextType {
  user: any;
  signin: (currentUser: any) => void;
  signout: () => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = React.useState<any>(null);
  const [mappings, setMappings] = React.useState<any>({});

  const signin = ({ user: currentUser, mappings }: any) => {
    setUser(currentUser);
    setMappings(mappings);
  };

  const signout = () => {
    setUser(null);
  };

  const value = { user, mappings, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.user && !get(location, 'pathname', '').includes('login')) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

export {
  Router,
  useAuth,
}