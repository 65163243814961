import { Collection } from "../services/collection";
import { post } from "../services/request";
import { template as table } from '../templates/tp-proposedpo';
import { template as edit } from '../templates/tp-proposedpo-edit';
import { template as view } from '../templates/tp-proposedpo-view';

export class Proposedpo extends Collection {
  constructor(auth?: any) {
    super('proposedpo', {
      table,
      edit,
      view
    }, auth);
  }
  public upload = async (reportNum: string) => post(`upload/${reportNum}`, {});
}