import { Card } from "antd";
import { set, sumBy } from "lodash";
import { Poresponse } from "../collections/poresponse";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const template = {
  id: "poresponseTable",
  collection: "poresponse",
  view: "table",
  template: {
    type: "space",
    items: [
      {
        id: "poresponse",
        type: "table",
        rowKey: "reportNum",
        canDelete: false,
        canEdit: false,
        canView: false,
        searchable: true,
        onSearch: (value: string, pipe: any) => {
          const { setFilters, setKeyword, refreshTable, handlePageChange } = pipe;
          setKeyword(value);
          setFilters(["reportNum", "poNum", "invoiceeEAN"]);
          handlePageChange({ page: 1 });
          refreshTable();
        },
        transform: {
          function: {
            optionsKey: "FUNC_CODE_MAPPING",
          },
        },
        extraActions: [
          {
            id: "upload",
            icon: {
              type: "icon",
              subType: "cloud-upload",
              theme: "outlined",
            },
            hidden: ({ data }: any) => data.status === 'UPLOADED',
            showLoading: true,
            onClick: async (event: any, pipe: any) => {
              const { setLoadings, refreshTable } = pipe;
              const { originalRecord, index } = event;
              try {
                const { reportNum } = originalRecord || {};
                const porSvc = new Poresponse();
                setLoadings((prevLoadings: any) => {
                  const newLoadings = { ...prevLoadings };
                  set(newLoadings, ["upload", index], true);
                  return newLoadings;
                });
                const { status, data } = await porSvc.upload(reportNum);
                if (status) {
                  showToast(
                    {
                      type: "success",
                      title: "GENERAL.SUCCESS",
                      description: `${status}`,
                    },
                    pipe.t
                  );
                  console.log(`${JSON.stringify(data)}`);
                }
              } catch (error) {
                showToast(
                  {
                    type: "error",
                    title: "ERROR.TITLE",
                    description: (error as ApiError).getMessage(),
                  },
                  pipe.t
                );
              } finally {
                setLoadings((prevLoadings: any) => {
                  const newLoadings = { ...prevLoadings };
                  set(newLoadings, ["upload", index], false);
                  return newLoadings;
                });
                refreshTable();
              }
            },
          },
        ],
        columns: [
          {
            title: "INVRPT.REPORT_NUM",
            dataIndex: "reportNum",
            key: "reportNum",
          },
          {
            title: "PO.PO_NUM",
            dataIndex: "poNum",
            key: "poNum",
          },
          {
            title: "PO.INVOICEE",
            dataIndex: "invoiceeEAN",
            key: "invoiceeEAN",
          },
          {
            title: "PO.MSG_DATE",
            dataIndex: "messageDate",
            key: "messageDate",
          },
          {
            title: "PO.CURRENCY",
            dataIndex: "currency",
            key: "currency",
          },
          {
            title: "POCHANGE.PO_CHANGE_NUM",
            dataIndex: "poChangeNum",
            key: "poChangeNum",
          },
          {
            title: "POCHANGE.FUNC",
            dataIndex: "function",
            key: "function",
          },
          {
            title: "COMMON.STATUS",
            dataIndex: "status",
            key: "status",
          },
        ],
        expandable: {
          rowKey: "lineNum",
          columns: [
            {
              title: "PO.GTIN",
              dataIndex: "itemEAN",
              key: "itemEAN",
            },
            {
              title: "PO.HAITEM_NUM",
              dataIndex: "haItemNum",
              key: "haItemNum",
            },
            {
              title: "PO.CONTRACT_NUM",
              dataIndex: "contractNum",
              key: "contractNum",
            },
            {
              title: "PO.DELIVERY_DATE",
              dataIndex: "deliverydate",
              key: "deliverydate",
            },
            {
              title: "PO.LOC_CODE",
              dataIndex: "locationCode",
              key: "locationCode",
            },
            {
              title: "PO.PRICE",
              dataIndex: "price",
              key: "price",
            },
            {
              title: "PO.QUANTITY",
              dataIndex: "quantity",
              key: "quantity",
              render: (data: any) => {
                return data && <div>{sumBy(data, "quantity")}</div>;
              },
            },
          ],
          id: "line",
          expandable: {
            rowKey: "notes",
            title: "PO.QUANTITY_NOTE",
            columns: [
              {
                title: "PO.TYPE",
                dataIndex: "type",
                key: "type",
              },
              {
                title: "PO.QUANTITY_NOTE",
                dataIndex: "notes",
                key: "notes",
                render: (data: any, record: any, index: number) => {
                  return (
                    data &&
                    data.map(({ text, type }: any) => {
                      return (
                        <Card
                          key={`q_notes_${index}`}
                          size="small"
                          title={type}
                          style={{ width: "100%" }}
                        >
                          {text && text.map((txt: string) => <p>{txt}</p>)}
                        </Card>
                      );
                    })
                  );
                },
              },
            ],
            id: "quantity",
          },
        },
      },
    ],
  },
};

export { template };
