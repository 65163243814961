import { BaseComponent } from './baseComponent';
import { Label } from './label';
import { Text } from './text';
import { TextArea } from './textarea';
import { NumberField } from './number';
import { DateField } from './date';
import { DateTimeField } from './datetime';
import { TimeField } from './time';
import { TableComponent } from './table';
import { ButtonComponent } from './button';
import { Picker } from './picker';
import { Icon } from './icon';
import { RowComponent } from './row';
import { ColComponent } from './col';
import { LevelPicker } from './levelPicker';
import { TimelineComponent } from './timeline';
import { Desc } from './desc';
import { UploadComponent } from './upload';

export const components: Record<string, BaseComponent> = {
  button: new ButtonComponent(),
  cascader: new LevelPicker(),
  col: new ColComponent(),
  date: new DateField(),
  datetime: new DateTimeField(),
  desc: new Desc(),
  icon: new Icon(),
  input: new Text(),
  label: new Label(),
  levelPicker: new LevelPicker(),
  number: new NumberField(),
  picker: new Picker(),
  row: new RowComponent(),
  table: new TableComponent(),
  text: new Text(),
  textarea: new TextArea(),
  time: new TimeField(),
  timeline: new TimelineComponent(),
  upload: new UploadComponent()
}