import { Admin } from "../collections/admin";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const onFinish = async (values: any, { t, navigate, auth }: any) => {
  console.log('Received values of form: ', values);
  const adminSvc = new Admin();
  const { username, password } = values;
  try {
    const currentUser = await adminSvc.login({ username, password });
    auth.signin(currentUser);
    navigate('/home');
  } catch (error: any) {
    showToast({
      type: 'error',
      title: 'ERROR.TITLE',
      description: (error as ApiError).getMessage()
    }, t)
  }
};

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};
const template = {
  id: 'login-form',
  template: {
    type: 'form',
    id: 'loginForm',
    default: {},
    items: [{
      type: 'input',
      id: 'username',
      placeholder: 'LOGIN.USERNAME',
      mandatory: true,
      mandatoryMessage: 'ERROR.MISS_VALUE_USERNAME',
      icon: {
        subType: 'user',
        type: 'icon',
        theme: 'outlined',
        cssClass: 'site-form-item-icon'
      }
    }, {
      type: 'input',
      subType: 'password',
      id: 'password',
      placeholder: 'LOGIN.PASSWORD',
      mandatory: true,
      mandatoryMessage: 'ERROR.MISS_VALUE_PASSWORD',
      icon: {
        subType: 'lock',
        type: 'icon',
        theme: 'outlined',
        cssClass: 'site-form-item-icon'
      }
    }, {
      type: 'button',
      id: 'login',
      subType: 'primary',
      htmlType: 'submit',
      cssClass: 'login-form-button',
      label: 'LOGIN.LOGIN'
    }],
    onFinish,
    onFinishFailed,
  }
}
export {
  template
}