import { Card } from "antd";
const template = {
  id: 'proposedpoView',
  collection: 'proposedpo',
  view: 'view',
  template: {
    type: 'dialog',
    title: 'PROPOSEDPO.VIEW',
    closable: true,
    hideFooter: true,
    items: [{
      type: 'desc',
      id: 'newView',
      bordered: true,
      layout: 'vertical',
      items: [{
        type: 'label',
        id: 'reportNum',
        label: 'INVRPT.REPORT_NUM',
      }, {
        type: 'label',
        id: 'storeEAN',
        label: 'INVRPT.STORE_LOC_NUM',
      }, {
        type: 'label',
        id: 'messageDate',
        label: 'PO.MSG_DATE',
      }, {
        type: 'label',
        id: 'despatchDate',
        label: 'ASN.DESPATCH_DATE',
      }, {
        type: 'label',
        id: 'function',
        label: 'POCHANGE.FUNC',
        optionsMap: {
          optionsKey: 'FUNC_CODE_MAPPING'
        }
      }],
    }, {
      type: 'table',
      rowKey: 'lineNum',
      canDelete: false,
      canEdit: false,
      canView: false,
      columns: [{
        title: 'PO.LINENUM',
        dataIndex: 'lineNum',
        key: 'lineNum'
      }, {
        title: 'PO.GTIN',
        dataIndex: 'itemEAN',
        key: 'itemEAN',
      }, {
        title: 'PO.HAITEM_NUM',
        dataIndex: 'haItemNum',
        key: 'haItemNum',
      }],
      id: 'line',
      expandable: {
        rowKey: 'notes',
        title: 'PO.QUANTITY_NOTE',
        transform: {
          type: {
            optionsKey: 'QUANTITY_CODE_MAPPING'
          }
        },
        columns: [{
          title: 'PO.QUANTITY',
          dataIndex: 'quantity',
          key: 'quantity',
        }, {
          title: 'PO.TYPE',
          dataIndex: 'type',
          key: 'type'
        }, {
          title: 'PO.QUANTITY_NOTE',
          dataIndex: 'notes',
          key: 'notes',
          render: (data: any, record: any, index: number) => {
            return data && data.map(({ text, type }: any) => {
              return <Card key={`q_notes_${index}`} size="small" title={type} style={{ width: '100%' }}>
                {text && text.map((txt: string) => <p>{txt}</p>)}
              </Card>
            })
          }
        }],
        id: 'quantity'
      }
    }]
  }
}

export {
  template
}