import { isFunction } from "lodash";
import { Inventory } from "../collections/inventory";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const onFinish = async (values: any, pipe: any) => {
  const { t, handleDialogConfirm, currentCollection, auth } = pipe;
  const updateModel = values;
  console.log('Received values of form: ', updateModel);
  const { mode } = currentCollection;
  const inventorySvc = new Inventory(auth);
  try {
    if (mode === 'new') {
      await inventorySvc.create(updateModel);
    } else {
      await inventorySvc.update(mode, updateModel);
    }
  } catch (error: any) {
    showToast({
      type: 'error',
      title: 'ERROR.TITLE',
      description: (error as ApiError).getMessage()
    }, t)
  } finally {
    if (isFunction(handleDialogConfirm)) {
      handleDialogConfirm(pipe);
    }
  }
};

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

const template = {
  id: 'inventoryEdit',
  collection: 'inventory',
  view: 'edit',
  template: {
    type: 'dialog',
    title: 'INVENTORY.NEW',
    closable: false,
    onOK: ({ model }: any) => {
      console.log('onOK', model);
    },
    onCancel: ({ model }: any) => {
      console.log('onCancel', model);
    },
    items: [{
      type: 'form',
      id: 'newForm',
      default: {},
      items: [{
        type: 'input',
        id: 'cfn',
        label: 'INVENTORY.CFN',
        placeholder: 'INVENTORY.CFN',
        mandatory: true,
      }, {
        type: 'input',
        id: 'name',
        label: 'INVENTORY.NAME',
        placeholder: 'INVENTORY.NAME',
        mandatory: true,
      }, {
        type: 'input',
        label: 'INVENTORY.DESCRIPTION',
        placeholder: 'INVENTORY.DESCRIPTION',
        id: 'description',
      }, {
        type: 'input',
        id: 'haItemCode',
        label: 'INVRPT.HAITEM_NUM',
        placeholder: 'INVRPT.HAITEM_NUM',
        mandatory: true,
      }, {
        type: 'input',
        id: 'gtin',
        label: 'INVRPT.GTIN',
        placeholder: 'INVRPT.GTIN',
        mandatory: true,
      }, {
        type: 'input',
        id: 'packSize',
        label: 'INVENTORY.PACKSIZE',
        placeholder: 'INVENTORY.PACKSIZE',
        mandatory: true,
      }, {
        id: 'lots',
        type: 'list',
        label: 'INVENTORY.LOTS',
        items: [{
          type: 'input',
          id: 'lotNo',
          label: 'INVENTORY.LOTNUM',
          placeholder: 'INVENTORY.LOTNUM',
        }, {
          type: 'input',
          id: 'expiryDate',
          label: 'INVENTORY.EXPIRYDATE',
          placeholder: 'INVENTORY.EXPIRYDATE',
        }, {
          type: 'input',
          id: 'quantity',
          label: 'INVENTORY.QUANTITY',
          placeholder: 'INVENTORY.QUANTITY',
          mandatory: true,
        }]
      }, {
        type: 'input',
        id: 'unitPrice',
        label: 'INVENTORY.PRICE',
        placeholder: 'INVENTORY.PRICE',
        mandatory: true,
      }, 
      {
        type: 'input',
        label: 'INVENTORY.UOM',
        placeholder: 'INVENTORY.UOM',
        id: 'uom',
      },
      {
        type: 'input',
        label: 'INVENTORY.BRAND',
        placeholder: 'INVENTORY.BRAND',
        id: 'brand',
      }, 
      {
        type: 'input',
        label: 'INVENTORY.MANUFACTURER',
        placeholder: 'INVENTORY.MANUFACTURER',
        id: 'manufacturer',
      }, 
      {
        type: 'input',
        label: 'INVENTORY.COUNTRY',
        placeholder: 'INVENTORY.COUNTRY',
        id: 'country',
      },
      {
        type: 'input',
        label: 'INVENTORY.CONTRACTNUM',
        placeholder: 'INVENTORY.CONTRACTNUM',
        id: 'contractNo',
      }],
      onFinish,
      onFinishFailed,
    }]
  }
}

export {
  template
}