import { find, get, map, sum, uniq } from "lodash";
import React from "react";

const PrintPage = React.forwardRef<any, any>(
  ({ pipe: { record: model, optionsMap } }, ref) => {
    const haItem = (value: string) =>
      find(
        get(optionsMap, "inventory", []),
        ({ gtin = "" }: any) => gtin === value
      ) || {};
    const findHaItemNum = (value: string) => get(haItem(value), "haItemCode");
    const buyer = get(optionsMap, "locations", []).find(
      ({ gln = "" }: any) => gln === get(model, "invoiceeEAN")
    );
    const bill = get(optionsMap, "locations", []).find(
      ({ gln = "" }: any) => gln === get(model, "invoiceeEAN")
    );
    const locationCodes = uniq(
      map(get(model, "line"), ({ locationCode }: any) => locationCode)
    );
    const store = get(optionsMap, "locations", []).find(
      ({ gln = "" }: any) => gln === locationCodes[0]
    );
    const total = sum(
      map(
        get(model, "line"),
        ({ price, quantity }) => +price * +quantity[0].quantity
      )
    );
    return (
      <div ref={ref}>
        <div
          style={{
            display: "block",
            width: "100%",
            padding: 16,
            position: "relative",
          }}
        >
          <div style={{ width: "100%", textAlign: "center" }}>
            <p>
              <b style={{ fontSize: 18 }}>PURCHASE ORDER</b>
            </p>
          </div>
          <div style={{ position: "absolute", right: 16, top: 16 }}>
            <table style={{ border: "1px solid", width: 360 }}>
              <tbody>
                <tr style={{ border: "1px solid" }}>
                  <td
                    style={{ border: "1px solid", paddingLeft: 4, width: 120 }}
                  >
                    Order No
                  </td>
                  <td
                    style={{ border: "1px solid", paddingLeft: 4, width: 240 }}
                  >
                    {get(model, "reportNum")}
                  </td>
                </tr>
                <tr style={{ border: "1px solid" }}>
                  <td
                    style={{ border: "1px solid", paddingLeft: 4, width: 120 }}
                  >
                    Revision
                  </td>
                  <td
                    style={{ border: "1px solid", paddingLeft: 4, width: 240 }}
                  >
                    {get(model, "poChangeNum", "0")}
                  </td>
                </tr>
                <tr style={{ border: "1px solid" }}>
                  <td
                    style={{ border: "1px solid", paddingLeft: 4, width: 120 }}
                  >
                    Original Date
                  </td>
                  <td
                    style={{ border: "1px solid", paddingLeft: 4, width: 240 }}
                  >
                    {get(model, "messageDate")}
                  </td>
                </tr>
                <tr style={{ border: "1px solid" }}>
                  <td
                    style={{ border: "1px solid", paddingLeft: 4, width: 120 }}
                  >
                    Revised Date
                  </td>
                  <td
                    style={{ border: "1px solid", paddingLeft: 4, width: 240 }}
                  >
                    &nbsp;
                  </td>
                </tr>
                <tr style={{ border: "1px solid" }}>
                  <td
                    style={{ border: "1px solid", paddingLeft: 4, width: 120 }}
                  >
                    Currency
                  </td>
                  <td
                    style={{ border: "1px solid", paddingLeft: 4, width: 240 }}
                  >
                    {get(model, "currency")}
                  </td>
                </tr>
                <tr style={{ border: "1px solid" }}>
                  <td
                    style={{ border: "1px solid", paddingLeft: 4, width: 120 }}
                  >
                    Total Amount
                  </td>
                  <td
                    style={{ border: "1px solid", paddingLeft: 4, width: 240 }}
                  >
                    {Number(total).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <b style={{ fontSize: 18 }}>This is an EDI PO</b>
          </div>
          <div
            style={{
              marginTop: 20,
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td
                    style={{ verticalAlign: "top", paddingRight: 8, width: 80 }}
                  >
                    Supplier:
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    DA HON ENTERPRISES COMPANY LIMITED
                  </td>
                </tr>
                <tr>
                  <td
                    style={{ verticalAlign: "top", paddingRight: 8, width: 80 }}
                  >
                    ATTN:
                  </td>
                  <td style={{ verticalAlign: "top" }}></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style={{
              marginTop: 24,
              flex: 1,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <table>
              <tbody>
                <tr>
                  <td
                    style={{
                      verticalAlign: "top",
                      paddingRight: 8,
                      width: 80,
                    }}
                  >
                    Bill-To:
                  </td>
                  <td>
                    {bill?.contact && (
                      <>
                        {bill?.contact} <br />
                      </>
                    )}
                    {bill?.code}
                    <br />
                    {bill?.addr1}
                    <br />
                    {bill?.addr2}
                    <br />
                    {bill?.addr3}
                    <br />
                    {bill?.addr4} {bill?.addr5}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      verticalAlign: "top",
                      paddingRight: 8,
                      width: 80,
                    }}
                  >
                    GLN:
                  </td>
                  <td>{get(model, "invoiceeEAN")}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      verticalAlign: "top",
                      paddingRight: 8,
                      width: 80,
                    }}
                  >
                    Tel:
                  </td>
                  <td>{bill?.phone}</td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <td
                    style={{
                      verticalAlign: "top",
                      paddingRight: 8,
                      width: 80,
                    }}
                  >
                    Ship-To:
                  </td>
                  <td>
                    {store?.contact && (
                      <>
                        {store?.contact} <br />
                      </>
                    )}
                    {store?.code}
                    <br />
                    {store?.addr1}
                    <br />
                    {store?.addr2}
                    <br />
                    {store?.addr3}
                    <br />
                    {store?.addr4} {store?.addr5}
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      verticalAlign: "top",
                      paddingRight: 8,
                      width: 80,
                    }}
                  >
                    GLN:
                  </td>
                  <td>{locationCodes[0]}</td>
                </tr>
                <tr>
                  <td
                    style={{
                      verticalAlign: "top",
                      paddingRight: 8,
                      width: 80,
                    }}
                  >
                    Tel:
                  </td>
                  <td>{store?.phone}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div>
            <table style={{ width: "100%", marginTop: 8 }}>
              <thead style={{ border: "1px solid" }}>
                <tr style={{ border: "1px solid" }}>
                  <th style={{ border: "1px solid", padding: 4 }}>Line No</th>
                  <th style={{ border: "1px solid", padding: 4 }}>
                    HA Item No
                  </th>
                  <th style={{ border: "1px solid", padding: 4 }}>Code</th>
                  <th style={{ border: "1px solid", padding: 4 }}>
                    Description
                  </th>
                  <th style={{ border: "1px solid", padding: 4 }}>Qty</th>
                  <th style={{ border: "1px solid", padding: 4 }}>Unit</th>
                  <th
                    style={{
                      border: "1px solid",
                      padding: 4,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Unit Price
                  </th>
                  <th style={{ border: "1px solid", padding: 4 }}>Amount</th>
                  <th
                    style={{
                      border: "1px solid",
                      padding: 4,
                      whiteSpace: "nowrap",
                    }}
                  >
                    Contract No
                  </th>
                  <th
                    style={{
                      border: "1px solid",
                      padding: 4,
                      whiteSpace: "nowrap",
                    }}
                  >
                    PPO No
                  </th>
                  <th style={{ border: "1px solid", padding: 4 }}>
                    Required Delivery Date
                  </th>
                  <th style={{ border: "1px solid", padding: 4 }}>
                    Ship-To Location
                  </th>
                </tr>
              </thead>
              <tbody>
                {map(get(model, "line"), (ln) => (
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        padding: 4,
                        verticalAlign: "top",
                      }}
                    >
                      {ln.lineNum}
                    </td>
                    <td style={{ padding: 4, verticalAlign: "top" }}>
                      {findHaItemNum(ln.itemEAN)}
                    </td>
                    <td style={{ padding: 4, verticalAlign: "top" }}>
                      {haItem(ln.itemEAN).cfn}
                    </td>
                    <td style={{ padding: 4 }}>
                      {haItem(ln.itemEAN)?.description}
                      <br />
                      {map(ln.quantity, ({ notes }: any) =>
                        map(notes, (note: any) => (
                          <div>
                            {note?.text?.map((txt: string) => (
                              <span>{txt}</span>
                            ))}
                          </div>
                        ))
                      )}
                    </td>
                    <td
                      style={{
                        padding: 4,
                        verticalAlign: "top",
                        textAlign: "end",
                      }}
                    >
                      {ln.quantity.map(({ quantity }: any) => (
                        <span>{quantity}</span>
                      ))}
                    </td>
                    <td
                      style={{
                        padding: 4,
                        verticalAlign: "top",
                        textAlign: "center",
                      }}
                    >
                      BOX
                    </td>
                    <td
                      style={{
                        padding: 4,
                        verticalAlign: "top",
                        textAlign: "end",
                      }}
                    >
                      {ln.price}
                    </td>
                    <td
                      style={{
                        padding: 4,
                        verticalAlign: "top",
                        textAlign: "end",
                      }}
                    >
                      {ln.quantity.map(({ quantity }: any) => (
                        <span>
                          {(+quantity * +ln.price).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                          })}
                        </span>
                      ))}
                    </td>
                    <td
                      style={{
                        padding: 4,
                        verticalAlign: "top",
                        textAlign: "center",
                      }}
                    >
                      {ln.contractNum}
                    </td>
                    <td
                      style={{
                        padding: 4,
                        verticalAlign: "top",
                        textAlign: "center",
                      }}
                    >
                      {ln.proposedPONum}
                    </td>
                    <td
                      style={{
                        padding: 4,
                        verticalAlign: "top",
                        textAlign: "center",
                      }}
                    >
                      {ln.deliverydate}
                    </td>
                    <td
                      style={{
                        padding: 4,
                        verticalAlign: "top",
                        textAlign: "end",
                      }}
                    >
                      {ln.locationCode}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ width: "100%", textAlign: "center", paddingTop: 16 }}>
            <b>
              TOTAL AMOUNT{" "}
              {total.toLocaleString("en-US", { minimumFractionDigits: 2 })}HKD
            </b>
          </div>
        </div>
      </div>
    );
  }
);

export { PrintPage };
