import { Col, Row, Avatar } from "antd";
import { get } from "lodash";
import './login.css';
import { template } from "../../templates/tp-login-form";
import { Templates } from "../../services/templates";
import { useNavigate } from "react-router";
import { useAuth } from "../../routes/routes";
import { Translation } from "react-i18next";

const LoginPage = (props: any) =>
<div className='login-page'>
  <Row justify="center" align="middle">
    <Col className='left-panel' span={6}>
      <Avatar size="large">
        {get(props, 'companyName', 'S')}
      </Avatar>
    </Col>
    <Col className='right-panel' span={18}>
      <LoginForm template={template}/>
    </Col>
  </Row>
</div>

const LoginForm = ({ template }: any) => {
  const auth = useAuth();
  const navigate = useNavigate();
  return (<Translation>{ t => <Templates template={template} pipe={{ auth, navigate, t }}/>}</Translation>)
}

export {
  LoginPage
}