import { post, get, put, deleted, upload } from "./request";
import { get as _get } from "lodash";

export class Collection {
  private _collection: string;
  private _templates: any;
  private _username: string;
  constructor(collection: string, templates?: any, auth?: any) {
    this._collection = collection;
    this._templates = templates;
    this._username = _get(auth, "user.username", "SYSTEM");
  }
  public list = (params?: any) => get(this._collection, params);

  public search = (params?: any) => post(`${this._collection}/list`, params);
  public find = (id: string) => get(`${this._collection}/${id}`);
  public create = (params: any) =>
    post(this._collection, { ...params, handledBy: this._username });
  public update = (id: string, params: any) =>
    put(this._collection, id, { ...params, handledBy: this._username });
  public remove = (id: string) => deleted(this._collection, id);
  public uploadFile = (file: any) => upload(this._collection, file);
  public getCollectionName = () => this._collection;
  public getTemplates = () => this._templates;
  public getTemplate = (view: string) => _get(this._templates, view);
}
