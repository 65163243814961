import { Button, Table, TablePaginationConfig } from "antd";
import Search from "antd/lib/input/Search";
import {
  cloneDeep,
  each,
  filter,
  find,
  get,
  isArray,
  isEmpty,
  isFunction,
  isPlainObject,
  join,
  map,
  set,
} from "lodash";
import { BaseComponent } from "./baseComponent";
import { DynamicIcon } from "./icon";

export class TableComponent extends BaseComponent {
  constructor() {
    super("table");
  }

  dataFormat = (value: any, dataType: any, def?: any) => {
    if (!value) {
      return def || value;
    }
    const { type } = dataType;
    switch (type) {
      case "string":
        return `${value}`;
      case "number":
        return +value;
      case "currency":
        return `$${Number(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}`;
    }
  };

  expandedRowRender = (record: any, item: any, expandable: any, pipe: any) => {
    if (expandable.transform) {
      each(
        expandable.transform,
        (
          {
            optionsKey: transformedOptionsKey,
            collection,
            showValue,
            labelKey,
            valueKey,
            extraValueKey,
            to,
          },
          key
        ) => {
          if (transformedOptionsKey) {
            set(
              expandable,
              ["options", key],
              get(pipe, ["optionsMap", transformedOptionsKey, "options"])
            );
          }
          if (collection) {
            const options = map(
              get(pipe, ["optionsMap", collection, "options"]),
              (opt) => ({
                ...opt,
                label: showValue
                  ? `${(isArray(labelKey) ? join(map(labelKey, k => get(opt, k)), ' ') : get(opt, labelKey))} (${get(opt, valueKey)})`
                  : (isArray(labelKey) ? join(map(labelKey, k => get(opt, k)), ' ') : get(opt, labelKey)),
                value: get(opt, valueKey),
                [extraValueKey]: get(opt, extraValueKey)
              })
            );
            set(expandable, ["options", key], options);
          }
          if (to) {
            set(expandable, ["to", key], to);
          }
        }
      );
    }
    const subDataSource = map(get(record, expandable.id), (row) => {
      const newRow = { ...row };
      each(row, (val, key) => {
        if (
          !isArray(val) &&
          !isPlainObject(val) &&
          get(expandable, ["options", key])
        ) {
          if (isArray(get(expandable, ["to", key]))) {
            each(get(expandable, ["to", key]), (t = key) => {
              const found = find(
                get(expandable, ["options", key]),
                ({ value }) => value === val || value === `${val}`
              );
              set(
                newRow,
                t,
                get(
                  found,
                  t, get(found, "label")
                )
              );
            }) 
          } else {
            let setKey = get(expandable, ["to", key]) || key;
            set(
              newRow,
              setKey,
              get(
                find(
                  get(expandable, ["options", key]),
                  ({ value }) => value === val || value === `${val}`
                ),
                "label"
              )
            );
          }
          
        }
      });
      return newRow;
    });
    return (
      <Table
        key={expandable.id}
        rowKey={expandable.rowKey}
        columns={map(expandable.columns, (col) => ({
          ...col,
          title: pipe.t(col.title),
        }))}
        dataSource={subDataSource}
        pagination={false}
        expandable={
          expandable.expandable && {
            defaultExpandAllRows: false,
            expandRowByClick: true,
            expandedRowRender: (subRecord) =>
              this.expandedRowRender(
                subRecord,
                item,
                expandable.expandable,
                pipe
              ),
          }
        }
      />
    );
  };

  public renderComponent(item: any, pipe: any): any {
    const {
      id,
      type,
      placeholder,
      cssClass,
      label,
      icon,
      dataType,
      columns,
      expandable,
      canEdit = true,
      canDelete = true,
      canView = true,
      extraActions = [],
      optionsMap,
      transform,
      searchable = false,
      standaloneSearch = true,
      onSearch = (e: any, pipe: any) => {},
      ...otherProps
    } = item;
    let dataSource = cloneDeep(get(pipe.model, id));
    if (dataType) {
      const dataKeys = Object.keys(dataType);
      dataSource = map(dataSource, (row) => {
        const newRow = { ...row };
        each(dataKeys, (key) => {
          set(
            newRow,
            key,
            this.dataFormat(get(newRow, key), dataType[key], "")
          );
        });
        return newRow;
      });
    }
    dataSource = map(dataSource, (row) => {
      const newRow = { ...row };
      each(row, (val, key) => {
        if (
          !isArray(val) &&
          !isPlainObject(val) &&
          get(item, ["options", key])
        ) {
          set(
            newRow,
            key,
            get(
              find(
                get(item, ["options", key]),
                ({ value }) => value === val || value === `${val}`
              ),
              "label"
            )
          );
        }
      });
      return newRow;
    });
    const originalData = cloneDeep(get(pipe.model, id));
    const actionCol = [];
    actionCol.push({
      title: "",
      dataIndex: "_id",
      key: "action",
      render: (text: any, record: any, index: number) =>
        filter(
          [
            (isFunction(canView) ? canView({ data: record }) : canView) && (
              <Button
                type="text"
                icon={<DynamicIcon type="eye" theme="outlined" />}
                onClick={(ev) =>
                  pipe.handleTableAction(
                    "view",
                    { ...ev, text, record, index },
                    pipe
                  )
                }
              ></Button>
            ),
            (isFunction(canEdit) ? canEdit({ data: record }) : canEdit) && (
              <Button
                type="text"
                icon={<DynamicIcon type="edit" theme="outlined" />}
                onClick={(ev) =>
                  pipe.handleTableAction(
                    "edit",
                    { ...ev, text, record, index },
                    pipe
                  )
                }
              ></Button>
            ),
            (isFunction(canDelete) ? canDelete({ data: record }) : canDelete) && (
              <Button
                type="text"
                icon={<DynamicIcon type="delete" theme="outlined" />}
                onClick={(ev) =>
                  pipe.handleTableAction(
                    "delete",
                    { ...ev, text, record, index },
                    pipe
                  )
                }
              ></Button>
            ),
            ...map(
              extraActions,
              ({ icon, id, onClick, showLoading: extraShowLoading, hidden = () => false }) => (
                !hidden({ data: originalData[index] }) && <Button
                  type="text"
                  icon={pipe.renderComponent(icon, pipe)}
                  loading={
                    extraShowLoading && get(pipe, ["loadings", id, index])
                  }
                  onClick={(ev) => {
                    let resume = true;
                    if (isFunction(onClick)) {
                      resume = onClick(
                        {
                          ...ev,
                          text,
                          originalRecord: originalData[index],
                          record,
                          index,
                        },
                        pipe
                      );
                    }
                    if (!resume) {
                      return;
                    }
                    pipe.handleTableAction(
                      id,
                      {
                        ...ev,
                        text,
                        originalRecord: originalData[index],
                        record,
                        index,
                      },
                      pipe
                    );
                  }}
                ></Button>
              )
            ),
          ],
          (action) => !!action
        ),
    });
    return (
      <>
        {searchable && standaloneSearch && (
          <>
            <Search
              placeholder="Search..."
              allowClear
              onSearch={(e) => onSearch(e, pipe)}
              style={{ width: "100%", padding: "0 16px" }}
            />
          </>
        )}
        <Table
          key={id}
          columns={[
            ...map(columns, (col) => ({ ...col, title: pipe.t(col.title) })),
            ...actionCol,
          ]}
          dataSource={dataSource}
          pagination={{
            position: ["bottomCenter"],
            current: get(pipe.model, "page"),
            defaultCurrent: 1,
            total: get(pipe.model, "total"),
            pageSize: get(pipe.model, "pageSize"),
          }}
          expandable={
            expandable && {
              indentSize: expandable.indentSize,
              defaultExpandAllRows: false,
              expandRowByClick: true,
              rowExpandable: (record) => !isEmpty(get(record, expandable.id)),
              expandedRowRender: (record) =>
                this.expandedRowRender(record, item, expandable, pipe),
            }
          }
          onChange={(
            pagination: TablePaginationConfig,
            filters: any,
            sorter: any,
            { currentDataSource, action }: any
          ) => {
            const { current, pageSize } = pagination;
            if (isFunction(pipe.handlePageChange)) {
              pipe.handlePageChange({
                page: current,
                pageSize
              });
            }
          }}
          {...otherProps}
        ></Table>
      </>
    );
  }
}
