import { get, isFunction, merge, map } from "lodash";
import { Asn } from "../collections/asn";
import { ApiError } from "../domains/error";
import { showToast } from "../services/toast";

const onFinish = async (values: any, pipe: any) => {
  const { t, handleDialogConfirm, currentCollection, extraValue, auth } = pipe;
  const { line } = values;
  const newLines = map(line, ln => ({ ...ln, refDate: ln.refDate.format('YYYY-MM-DD') }));
  const updateModel = merge({ ...values, line: newLines, messageDate: get(values, 'messageDate').format('YYYY-MM-DD'), despatchDate: get(values, 'despatchDate').format('YYYY-MM-DD') }, extraValue);
  console.log('Received values of form: ', updateModel);
  const { mode } = currentCollection;
  const asnSvc = new Asn(auth);
  try {
    if (mode === 'new') {
      await asnSvc.create(updateModel);
    } else {
      await asnSvc.update(mode, updateModel);
    }
  } catch (error: any) {
    showToast({
      type: 'error',
      title: 'ERROR.TITLE',
      description: (error as ApiError).getMessage()
    }, t)
  } finally {
    if (isFunction(handleDialogConfirm)) {
      handleDialogConfirm(pipe);
    }
  }
};

const onFinishFailed = (errorInfo: any) => {
  console.log('Failed:', errorInfo);
};

const template = {
  id: 'asnEdit',
  collection: 'asn',
  view: 'edit',
  template: {
    type: 'dialog',
    title: 'ASN.NEW',
    closable: false,
    onOK: ({ model }: any) => {
      console.log('onOK', model);
    },
    onCancel: ({ model }: any) => {
      console.log('onCancel', model);
    },
    items: [{
      type: 'form',
      id: 'newForm',
      default: {
        function: '16',
      },
      items: [{
        type: 'input',
        id: 'reportNum',
        label: 'INVRPT.REPORT_NUM',
        placeholder: 'INVRPT.REPORT_NUM',
        disabled: true,
      }, {
        type: 'input',
        id: 'poNum',
        label: 'PO.PO_NUM',
        placeholder: 'PO.PO_NUM',
        disabled: true,
      },  {
        type: 'input',
        id: 'deliveryPartyEAN',
        label: 'ASN.DELIVERY_PARTY',
        placeholder: 'ASN.DELIVERY_PARTY',
        mandatory: true,
        optionsMap: {
          collection: 'store',
          valueKey: 'asnBuyerLocEAN',
          labelKey: 'name',
          showValue: true,
          extraValueKeys: [{
            id: 'ppoBuyerLocEAN',
            valueKey: 'ppoBuyerLocEAN'
          }]
        }
      }, {
        type: 'input',
        id: 'ssccNum',
        label: 'ASN.SSCC',
        placeholder: 'ASN.SSCC',
      }, {
        type: 'date',
        id: 'messageDate',
        label: 'PO.MSG_DATE',
        placeholder: 'PO.MSG_DATE',
        format: 'YYYY-MM-DD',
        mandatory: true,
      }, {
        type: 'date',
        id: 'despatchDate',
        label: 'ASN.DESPATCH_DATE',
        placeholder: 'ASN.DESPATCH_DATE',
        format: 'YYYY-MM-DD',
        mandatory: true,
      }, {
        type: 'input',
        id: 'despatchNoteNum',
        label: 'ASN.DESPATCH_NOTE_NUM',
        placeholder: 'ASN.DESPATCH_NOTE_NUM',
        mandatory: true,
      }, {
        type: 'list',
        id: 'line',
        label: 'PROPOSEDPO.LINE',
        mandatory: true,
        items: [{
          type: 'picker',
          id: 'itemEAN',
          label: 'PO.GTIN',
          placeholder: 'PO.GTIN',
          mandatory: true,
          showSearch: true,
          optionFilterProp: "children",
          filterOption: (input: any, option: any) => input && option && option.value && option.value.toLowerCase().includes(input.toLowerCase()),
          optionsMap: {
            collection: 'inventory',
            valueKey: 'gtin',
            labelKey: 'name',
            showValue: true,
            extraValueKeys: [{
              id: 'haItemNum',
              valueKey: 'haItemCode'
            }, {
              id: 'manufacturers.0.name',
              valueKey: 'manufacturer'
            }, {
              id: 'manufacturers.0.country',
              valueKey: 'country'
            }]
          }
        }, {
          type: 'input',
          id: 'batchNum',
          label: 'ASN.BATCH_NUM',
          placeholder: 'ASN.BATCH_NUM',
          mandatory: true,
        }, {
          type: 'date',
          id: 'refDate',
          label: 'ASN.REF_DATE',
          placeholder: 'ASN.REF_DATE',
          format: 'YYYY-MM-DD',
          mandatory: true,
        }, {
          type: 'input',
          id: 'poLineNum',
          label: 'PO.PO_LINENUM',
          placeholder: 'PO.PO_LINENUM',
          mandatory: true,
        }, {
          type: 'list',
          id: 'manufacturers',
          label: 'PO.MANUFACTURERS',
          items: [{
            type: 'picker',
            id: 'country',
            label: 'PO.COUNTRY',
            placeholder: 'PO.COUNTRY',
            optionsMap: {
              optionsKey: 'COO_MAPPING'
            }
          }, {
            type: 'input',
            id: 'name',
            label: 'PO.MANUFACTURERS_NAME',
            placeholder: 'PO.MANUFACTURERS_NAME',
          }]
        }, {
          type: 'list',
          id: 'quantity',
          label: 'PO.QUANTITY',
          mandatory: true,
          items: [{
            type: 'number',
            id: 'quantity',
            label: 'PO.QUANTITY',
            placeholder: 'PO.QUANTITY',
            mandatory: true,
          }, {
            type: 'picker',
            id: 'type',
            label: 'PO.TYPE',
            placeholder: 'PO.TYPE',
            mandatory: true,
            disabled: true,
            initialValue: '12',
            optionsMap: {
              optionsKey: 'QUANTITY_CODE_MAPPING'
            }
          }]
        }]
      }],
      onFinish,
      onFinishFailed,
    }]
  }
}

export {
  template
}