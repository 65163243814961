
import { capitalize } from "lodash";
import { BaseComponent } from "./baseComponent";

export const DynamicIcon = ({ type, theme, ...rest }: any) => {
  const icons = require(`@ant-design/icons`);
  const [iconName, variant] = type.split('-');
  const Component = icons[`${capitalize(iconName)}${capitalize(variant) || ''}${capitalize(theme)}`];
  return <Component {...rest}/>
}

export class Icon extends BaseComponent {
  constructor() {
    super('icon');
  }

  public renderComponent(item: any, pipe: any): any {
    const {
      id, subType, cssClass, theme,
    } = item;
    return <DynamicIcon key={id} type={ subType } className={ cssClass } theme={theme}></DynamicIcon>
  }
};
